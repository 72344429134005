import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import './transitionBackground.scss';

const TransitionBackground = React.memo(props => {
    // TODO why is click serviced by onSelect?
    // TODO fix speed - needs 's'
    const [currentPhase, setCurrentPhase] = useState(props.phase);
    const [nextPhase] = useState(props.phase);
    const [step, setStep] = useState('idle');
    const [transitioning, setTransitioning] = useState(false);

    useEffect(() => {
        setCurrentPhase(transitioning ? nextPhase : props.phase);
        setStep('transition');
        setTransitioning(true);
    }, [transitioning, nextPhase, step, currentPhase, props]);

    const getSpeed = modifier => {
        const speeds = ['immediate', 'very-fast', 'fast', 'normal', 'slow', 'very-slow'];
        const speedValues = ['0.0001s', '0.175s', '0.3125s', '0.45s', '0.675s', '0.9s'];
        let speed = props.speed;
        let index = indexOf(speeds, speed);

        if (index !== -1) {
            if (modifier === 'faster' && index > 0) {
                index -= 1;
            }

            if (modifier === 'slower' && index < speeds.length - 1) {
                index += 1;
            }

            speed = speedValues[index];
        }

        return speed;
    };

    const toArray = value => {
        return isArray(value) ? value : [value];
    };

    const isFadeActionPresent = phases => {
        return includes(phases, 'fade');
    };

    const getCurrentTransitioningStyles = () => {
        const phaseTypes = toArray(props.phaseTypes);
        const transformActions = {
            'enter-from-bottom': 'translate3d(0, -100%, 0)',
            'enter-from-left': 'translate3d(100%, 0, 0)',
            'enter-from-right': 'translate3d(-100%, 0, 0)',
            'enter-from-top': 'translate3d(0, 100%, 0)',
            'scale-horizontally': 'scaleX(0)',
            'scale-vertically': 'scaleY(0)'
        };
        let style = {};
        let transformStyles = [];

        phaseTypes.forEach(phaseType => {
            if (transformActions[phaseType]) {
                transformStyles.push(transformActions[phaseType]);
            }
        });

        if (transformStyles.length) {
            style.transform = transformStyles.join(' ');
        }

        if (isFadeActionPresent(phaseTypes)) {
            style.opacity = 0;
        }

        return style;
    };

    const getTransitionerStyles = () => {
        const phaseTypes = toArray(props.phaseTypes);
        const speedContent = getSpeed();
        let transitionStyles = [];
        let delay = props.delay;
        let speedOpacity;
        let style = {};

        delay = delay === 0 ? 0.01 : delay;

        if (isFadeActionPresent(phaseTypes)) {
            if (phaseTypes.length > 1) {
                transitionStyles.push('transform ' + speedContent + ' ' + delay + 's');
                delay += 0.15;
                speedOpacity = getSpeed('slower');
                transitionStyles.push('opacity ' + speedOpacity + ' ' + delay + 's');
            } else {
                transitionStyles.push('opacity ' + speedContent + ' ' + delay + 's');
            }
        } else if (phaseTypes[0] !== 'none') {
            transitionStyles.push('transform ' + speedContent + ' ' + delay + 's');
        }

        if (transitionStyles.length) {
            style = {
                transition: transitionStyles.join(', ')
            };
        }

        return style;
    };

    const getCurrentStyle = () => {
        let currentStyle = {};

        if (step === 'transition') {
            currentStyle = assignIn(getCurrentTransitioningStyles(), getTransitionerStyles());
        }

        return assignIn(currentStyle, props.contentStyle);
    };

    const handleEventToPreventBubble = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleTransitionEnd = event => {
        const onTransitionEnd = props.onTransitionEnd;

        handleEventToPreventBubble(event);
        setCurrentPhase(nextPhase);
        setStep('idle');
        setTransitioning(false);

        if (onTransitionEnd) {
            onTransitionEnd(props.name);
        }
    };

    const getPhaseClass = phase => {
        let classes = {
            'transition-background--inactive': phase === 'inactive',
            'transition-background--primary': phase === 'primary',
            'transition-background--secondary': phase === 'secondary',
            'transition-background--tertiary': phase === 'tertiary'
        };

        // handle custom class
        if (phase !== 'inactive' && phase !== 'primary' && phase !== 'secondary' && phase !== 'tertiary') {
            classes[phase] = true;
        }

        return classes;
    };

    const getCurrentClass = () => {
        return classNames('transition-background--content', getPhaseClass(currentPhase), props.contentClassName);
    };

    const getNewClass = () => {
        return classNames('transition-background--content', getPhaseClass(nextPhase), props.contentClassName);
    };

    const getClass = () => {
        return classNames('transition-background', props.className);
    };

    const getCurrentProps = () => {
        return {
            className: getCurrentClass(),
            onTransitionEnd: handleTransitionEnd,
            style: getCurrentStyle()
        };
    };

    const getNewProps = () => {
        return {
            className: getNewClass(),
            onTransitionEnd: handleEventToPreventBubble,
            style: props.contentStyle
        };
    };

    const getProps = () => {
        return {
            'aria-describedby': props['aria-describedby'],
            'aria-label': props['aria-label'],
            'aria-labelledby': props['aria-labelledby'],
            'aria-pressed': props['aria-pressed'],
            className: getClass(),
            disabled: props.disabled,
            href: props.href,
            id: props.id,
            onBlur: props.onBlur,
            onClick: props.onSelect,
            onFocus: props.onFocus,
            onMouseDown: props.onMouseDown,
            onMouseEnter: props.onHoverBegin,
            onMouseLeave: props.onHoverEnd,
            onMouseUp: props.onMouseUp,
            onTransitionEnd: props.onTransitionEnd,
            role: props.role,
            style: props.style,
            tabIndex: props.tabIndex
        };
    };

    const Component = props.Component;

    return (
        <Component {...getProps()}>
            <div {...getNewProps()}></div>
            <div {...getCurrentProps()}></div>
            {props.children}
        </Component>
    );
});

TransitionBackground.propTypes = {
    Component: PropTypes.string,
    'aria-describedby': PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-pressed': PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.object,
    delay: PropTypes.number,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onHoverBegin: PropTypes.func,
    onHoverEnd: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onSelect: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    phase: PropTypes.oneOfType([PropTypes.oneOf(['inactive', 'primary', 'secondary', 'tertiary']), PropTypes.string]),
    phaseTypes: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOf([
                'enter-from-bottom',
                'enter-from-left',
                'enter-from-right',
                'enter-from-top',
                'fade',
                'none',
                'scale-horizontally',
                'scale-vertically'
            ])
        ),
        PropTypes.oneOf([
            'enter-from-bottom',
            'enter-from-left',
            'enter-from-right',
            'enter-from-top',
            'fade',
            'none',
            'scale-horizontally',
            'scale-vertically'
        ])
    ]),
    role: PropTypes.string,
    speed: PropTypes.oneOfType([
        PropTypes.oneOf(['fast', 'immediate', 'normal', 'slow', 'very-fast', 'very-slow']),
        PropTypes.string
    ]),
    style: PropTypes.object,
    tabIndex: PropTypes.number
};

TransitionBackground.defaultProps = {
    Component: 'div',
    delay: 0.01,
    phase: 'inactive',
    phaseTypes: 'fade',
    speed: 'normal'
};

export default TransitionBackground;
