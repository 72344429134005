import classNames from 'classnames';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React from 'react';
import TransitionBackground from './transitionBackground';
import './shape.scss';

const Shape = props => {
    const makeIntoPixels = value => {
        return value + 'px';
    };

    const isSquare = () => {
        return props.shapeType === 'square';
    };

    const isCircle = () => {
        return props.shapeType === 'circle';
    };

    const isTriangle = () => {
        return props.shapeType === 'triangle';
    };

    const isEllipse = () => {
        return props.shapeType === 'ellipse';
    };

    const isStandardColor = colorType => {
        let color = colorType === 'fill' ? props.fillColor : props.strokeColor;
        const standardColors = [
            'neutral-black',
            'neutral-gray1',
            'neutral-gray2',
            'neutral-gray3',
            'neutral-gray4',
            'neutral-white',
            'primary-blue',
            'primary-blue-darkened',
            'primary-blue-lightened',
            'primary-dark-blue',
            'primary-dark-yellow',
            'primary-light-yellow',
            'primary-red',
            'primary-red-lightened',
            'primary-yellow',
            'secondary-blue',
            'secondary-dark-blue',
            'secondary-gray',
            'secondary-green',
            'secondary-green-lightened',
            'secondary-light-blue',
            'secondary-orange',
            'secondary-teal',
            'secondary-yellow'
        ];

        return includes(standardColors, color);
    };

    const getContentStyle = () => {
        const borderWidth = props.borderWidth;
        let height = props.height;
        let hypotenuse;
        let top = 0;
        let width = props.width;

        if (isCircle() || isSquare() || isTriangle()) {
            height = height || width;
            width = height;
        }

        if (isTriangle()) {
            hypotenuse = (Math.sqrt(2) * width) / 2;
            top = Math.round(hypotenuse / -2);
            hypotenuse = Math.round(hypotenuse);
            height = hypotenuse;
            width = hypotenuse;
        }

        return {
            borderWidth: borderWidth,
            height: makeIntoPixels(height),
            top: top,
            width: makeIntoPixels(width)
        };
    };

    const getStyle = () => {
        const triangle = isTriangle();
        let height = props.height;
        let style;
        let width = props.width;

        if (isCircle() || isSquare() || triangle) {
            height = height || width;
            width = height;

            if (triangle) {
                height = height / 2 + props.borderWidth;
            }
        }

        style = {
            borderWidth: props.borderWidth,
            height: makeIntoPixels(height),
            width: makeIntoPixels(width)
        };

        return style;
    };

    const getContentClassName = () => {
        const triangle = isTriangle();

        return classNames({
            'shape--ellipse': isEllipse() || isCircle(),
            'shape--stroke': props.borderWidth && !triangle,
            'shape--triangle-content': triangle
        });
    };

    const getPhaseColorClass = () => {
        let classes = {};
        let fillColor = props.fillColor;
        let strokeColor = props.strokeColor;

        if (fillColor) {
            if (isStandardColor('fill')) {
                fillColor = 'swa-g-color-bg-' + fillColor;
            }

            classes[fillColor] = true;
        }

        if (strokeColor) {
            if (isStandardColor('stroke')) {
                strokeColor = 'shape--stroke-' + strokeColor;
            }

            classes[strokeColor] = true;
        }

        return classNames(classes);
    };

    const getStrokeColorClass = () => {
        let strokeColor = props.strokeColor;

        if (isStandardColor('stroke')) {
            strokeColor = 'shape--stroke-' + strokeColor;
        }

        return strokeColor;
    };

    const getClass = () => {
        return classNames(
            {
                shape: true,
                'shape--triangle': isTriangle()
            },
            getStrokeColorClass(),
            props.className
        );
    };

    const getProps = () => {
        return {
            className: getClass(),
            contentClassName: getContentClassName(),
            contentStyle: getContentStyle(),
            name: props.name,
            onTransitionEnd: props.onTransitionEnd,
            phase: getPhaseColorClass(),
            phaseTypes: props.phaseTypes,
            speed: props.speed,
            style: getStyle()
        };
    };

    return <TransitionBackground {...getProps()}>{props.children}</TransitionBackground>;
};

Shape.propTypes = {
    borderWidth: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    fillColor: PropTypes.oneOfType([
        PropTypes.oneOf([
            'neutral-black',
            'neutral-gray1',
            'neutral-gray2',
            'neutral-gray3',
            'neutral-gray4',
            'neutral-white',
            'primary-blue',
            'primary-blue-darkened',
            'primary-blue-lightened',
            'primary-dark-blue',
            'primary-dark-yellow',
            'primary-light-yellow',
            'primary-red',
            'primary-red-lightened',
            'primary-yellow',
            'secondary-blue',
            'secondary-dark-blue',
            'secondary-gray',
            'secondary-green',
            'secondary-green-lightened',
            'secondary-light-blue',
            'secondary-orange',
            'secondary-teal',
            'secondary-yellow'
        ]),
        PropTypes.string
    ]),
    height: PropTypes.number,
    name: PropTypes.string,
    onTransitionEnd: PropTypes.func,
    phaseTypes: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOf([
                'enter-from-bottom',
                'enter-from-left',
                'enter-from-right',
                'enter-from-top',
                'fade',
                'none',
                'scale-horizontally',
                'scale-vertically'
            ])
        ),
        PropTypes.oneOf([
            'enter-from-bottom',
            'enter-from-left',
            'enter-from-right',
            'enter-from-top',
            'fade',
            'none',
            'scale-horizontally',
            'scale-vertically'
        ])
    ]),
    shapeType: PropTypes.oneOf(['circle', 'ellipse', 'rectangle', 'square', 'triangle']),
    speed: PropTypes.oneOfType([
        PropTypes.oneOf(['fast', 'immediate', 'normal', 'slow', 'very-fast', 'very-slow']),
        PropTypes.string
    ]),
    strokeColor: PropTypes.oneOfType([
        PropTypes.oneOf([
            'neutral-black',
            'neutral-gray1',
            'neutral-gray2',
            'neutral-gray3',
            'neutral-gray4',
            'neutral-white',
            'primary-blue',
            'primary-blue-darkened',
            'primary-blue-lightened',
            'primary-dark-blue',
            'primary-dark-yellow',
            'primary-light-yellow',
            'primary-red',
            'primary-red-lightened',
            'primary-yellow',
            'secondary-blue',
            'secondary-dark-blue',
            'secondary-gray',
            'secondary-green',
            'secondary-green-lightened',
            'secondary-light-blue',
            'secondary-orange',
            'secondary-teal',
            'secondary-yellow'
        ]),
        PropTypes.string
    ]),
    width: PropTypes.number
};

Shape.defaultProps = {
    borderWidth: 0,
    height: 100,
    phaseTypes: 'none',
    shape: 'rectangle',
    width: 100
};

export default Shape;
