import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import filterDOMProps from '../utils/dom-whitelist/filter-dom-props';
import { getConfig } from './config/config';
import Heading from './heading';
import Icon from './icon';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import PropTypes from 'prop-types';
import './message.scss';

const Message = props => {
    const { alert, children, className, classNameMessageContent, icon, messageTitle, type } = props;

    const usesHeadingComponent = () => {
        return type !== 'system-message';
    };

    const getClassMessageContent = () => {
        return classNames('message--content', classNameMessageContent);
    };

    const getIconType = () => {
        const icons = {
            caution: 'swa-icon_info-circle',
            error: 'swa-icon_error',
            information: 'swa-icon_info-circle',
            'information-number': 'swa-icon_number-circle-one',
            success: 'swa-icon_check-circle',
            'system-message': 'swa-icon_error',
            warning: 'swa-icon_error'
        };

        return icon || icons[type];
    };

    const getIconSize = () => {
        const iconSizes = {
            'system-message': 'extra-small'
        };

        return alert ? 'extra-large' : iconSizes[type] || 'medium';
    };

    const getClass = () => {
        const classes = {
            message: true,
            message_alert: alert,
            message_caution: type === 'caution',
            message_error: type === 'error',
            message_information: type === 'information',
            'message_information-number': type === 'information-number',
            'message_no-content': isUndefined(children) && alert,
            message_success: type === 'success',
            'message_system-message': type === 'system-message',
            message_warning: type === 'warning'
        };

        return classNames(classes, className);
    };

    const getTitleProps = () => {
        return {
            className: 'message--title',
            size: usesHeadingComponent() ? 'medium' : undefined
        };
    };

    const getIconProps = () => {
        return {
            className: 'message--icon',
            icon: getIconType(),
            size: getIconSize()
        };
    };

    const getProps = () => {
        const extendedProps = assignIn({}, props, {
            className: getClass()
        });

        return filterDOMProps.html(extendedProps);
    };

    const renderTitle = () => {
        const Component = usesHeadingComponent() ? Heading : 'div';
        let title = null;

        if (messageTitle) {
            title = <Component {...getTitleProps()}>{messageTitle}</Component>;
        }

        return title;
    };

    const renderMessageContent = () => {
        return (
            <div className={getClassMessageContent()}>
                {renderTitle()}
                {children}
            </div>
        );
    };

    const renderIcon = () => {
        let content = null;

        if (icon !== 'noIcon') {
            content = <Icon {...getIconProps()} />;
        }

        return content;
    };

    return (
        <div {...getProps()}>
            {renderIcon()}
            {renderMessageContent()}
        </div>
    );
};

Message.propTypes = {
    alert: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    classNameMessageContent: PropTypes.string,
    icon: PropTypes.string,
    messageTitle: PropTypes.node,
    type: PropTypes.oneOf([
        'caution',
        'error',
        'information',
        'information-number',
        'success',
        'system-message',
        'warning'
    ])
};

Message.defaultProps = assignIn(
    {
        type: 'warning'
    },
    getConfig('Message')
);

export default Message;
