let config = {};

const getConfig = componentName => {
    return config[componentName] || {};
};

const setConfig = configSettings => {
    config = configSettings;
};

export { getConfig, setConfig };
