import constant from 'lodash/constant';

export const i18nGlobal = {
    BUTTON__SUBMIT: constant('Submit'),
    ERROR__INVALID_ID_PASSWORD: constant('Invalid User ID or Password'),
    ERROR__LOGIN_SERVICE: constant('An error has occurred. Please try again.'),
    LABEL__PASSWORD: constant('Password'),
    LABEL__USERNAME: constant('User ID'),
    LINK__FORGOT_PASSWORD: constant('Forgot Password'),
    LINK__RESTART_LOGIN: constant('Restart Login'),
    PAGE_TITLE__LANDING_HOME_PAGE_LOGIN: constant('SWA Nonrev | Login'),
    TEXT__COMPANY_LOGO: constant('Company Logo'),
    TITLE__LOGIN_FORM_TITLE: constant('Please enter your User ID below.'),
    TITLE__LOGIN_TITLE: constant('Southwest Airlines Nonrevenue Travel')
};
