import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import externalReducer from '../external';
import { getPersistConfig } from './configPersist';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import v4 from 'uuid/v4';

const setInitialStateWithExternalId = initialState => {
    return {
        ...initialState,
        external: {
            id: v4()
        }
    };
};

const configureExternalPersistReducer = overrides => {
    const config = {
        key: 'external',
        whitelist: ['id']
    };

    return persistReducer(getPersistConfig(config, overrides), externalReducer);
};

const createPersistedReducer = config => {
    const rootReducer = combineReducers({
        ...config.reducerConfig,
        external: configureExternalPersistReducer(config.persistOverrides)
    });

    return persistReducer(getPersistConfig(config.persistConfig, config.persistOverrides), rootReducer);
};

export default config => {
    const persistConfig = createPersistedReducer(config);
    const initialState = config.initialState ? setInitialStateWithExternalId(config.initialState) : {};
    const store = createStore(persistConfig, initialState, composeWithDevTools(applyMiddleware(thunk)));
    const persistor = persistStore(store);

    return { persistor, store };
};
