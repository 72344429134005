const initialState = 'EXT_ID_NOT_FOUND';

const externalReducer = (state = initialState, action = undefined) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default externalReducer;
