import * as types from 'modules/placements/types';
import { getAppConfig, getHeaderProps } from 'modules/systemData';
import { getBootstrapData } from 'swa-bootstrap';
import { getExternalId } from 'modules/external';
import { getSession } from 'modules/session';
import { logServiceErrors } from 'utils/transformErrorForLogger';
import { post } from 'api/serviceCaller';

const retrievePlacementsSuccessful = response => {
    return {
        payload: response,
        type: types.RETRIEVE_PLACEMENTS_SUCCESS
    };
};

const retrievePlacementsFailure = pageId => {
    const content = getBootstrapData('bootstrap-content/en/content')[pageId];

    return {
        payload: content,
        type: types.RETRIEVE_PLACEMENTS_FAILURE
    };
};

export const retrievePlacements = pageId => {
    return async (dispatch, getState) => {
        const currentState = getState();
        const appConfig = getAppConfig(currentState);
        const headerProps = getHeaderProps(currentState);
        const session = getSession(currentState);
        const options = {
            headerProps: {
                ...headerProps,
                uuid: getExternalId(currentState)
            },
            session: session
        };

        const body = {
            appId: appConfig.appId,
            application: appConfig.appId,
            lang: appConfig.lang,
            pageId: pageId,
            site: appConfig.site
        };

        try {
            const response = await post('placements', body, options);

            dispatch(retrievePlacementsSuccessful(response));
        } catch (error) {
            if (error.response) {
                dispatch(retrievePlacementsFailure(pageId));
                logServiceErrors(error.response, 'placements');
            } else {
                setTimeout(() => {
                    throw error;
                }, 0);
            }
        }
    };
};
