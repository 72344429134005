import classNames from 'classnames';
import assignIn from 'lodash/assignIn';
import { getConfig } from './config/config';
import Input from './input';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import './password.scss';

const propTypes = {
    blocked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dynamicBlacklistWords: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.bool,
    formControlProps: PropTypes.shape({
        helperText: PropTypes.string,
        labelText: PropTypes.string.isRequired,
        required: PropTypes.bool,
        width: PropTypes.oneOf(['full', 'large', 'medium', 'micro', 'none', 'small'])
    }),
    inputType: PropTypes.oneOf([
        'primary',
        'primary-simple',
        'secondary',
        'secondary-simple',
        'secondary-extra-small',
        'secondary-small',
        'tertiary'
    ]),
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onMouseDown: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    role: PropTypes.string,
    staticBlacklistWords: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string
};

const defaultProps = assignIn(
    {
        maxLength: 16,
        staticBlacklistWords: []
    },
    getConfig('Password')
);

const Password = props => {
    const { className, onChange, maxLength } = props;

    const handleInputChange = event => {
        if (onChange) {
            onChange(event);
        }
    };

    const getClass = () => {
        return classNames('password', className);
    };

    const getProps = () => {
        let formControlProps;
        let inputProps = omit(props, ['className', 'errors']);

        formControlProps = omit(inputProps.formControlProps, ['errors']);

        return assignIn({}, inputProps, {
            formControlProps: assignIn({}, formControlProps, { noMinHeight: true }),
            maxLength: maxLength,
            onChange: handleInputChange,
            type: 'password',
            wrapWithFormControl: true
        });
    };

    const renderInput = () => {
        return (
            <div className="password--input">
                <Input {...getProps()} />
            </div>
        );
    };

    return <div className={getClass()}>{renderInput()}</div>;
};

Password.propTypes = propTypes;
Password.defaultProps = defaultProps;

export default Password;
