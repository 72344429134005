import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import filterDOMProps from '../utils/dom-whitelist/filter-dom-props';
import { getConfig } from './config/config';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import './icon.scss';

const Icon = forwardRef((props, ref) => {
    const getIconName = () => {
        const icon = props.icon;
        const iconPrefix = props.iconPrefix;

        return !includes(icon, iconPrefix) ? iconPrefix + icon : icon;
    };

    const hasAriaProps = () => {
        return props['aria-label'] || props['aria-labelledby'] || props['aria-describedby'] || props.title;
    };

    const getClass = () => {
        const size = props.size;
        const classes = {
            'swa-icon': true,
            'swa-icon_extra-large': size === 'extra-large',
            'swa-icon_extra-small': size === 'extra-small',
            'swa-icon_jumbo': size === 'jumbo',
            'swa-icon_large': size === 'large',
            'swa-icon_medium': size === 'medium',
            'swa-icon_micro': size === 'micro',
            'swa-icon_nano': size === 'nano',
            'swa-icon_over-large': size === 'over-large',
            'swa-icon_semi-medium': size === 'semi-medium',
            'swa-icon_semi-small': size === 'semi-small',
            'swa-icon_small': size === 'small'
        };

        return classNames(classes, props.className, getIconName());
    };

    const getProps = () => {
        let attributes = assignIn({}, props, {
            className: getClass(),
            ref: ref
        });

        if (!hasAriaProps()) {
            attributes.role = 'presentation';
        }

        if (isNumber(attributes.size)) {
            attributes.style = assignIn({}, attributes.style, {
                fontSize: attributes.size
            });
        }

        return filterDOMProps.html(attributes);
    };

    return (
        <span {...getProps()}>
            <span role="presentation" className="swa-icon--icon" />
        </span>
    );
});

Icon.propTypes = {
    'aria-describedby': PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconPrefix: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.oneOf([
            'nano',
            'micro',
            'extra-small',
            'semi-small',
            'small',
            'semi-medium',
            'medium',
            'large',
            'over-large',
            'extra-large',
            'jumbo'
        ]),
        PropTypes.number
    ]),
    style: PropTypes.object,
    title: PropTypes.string
};

Icon.defaultProps = assignIn(
    {
        iconPrefix: 'swa-icon_',
        role: 'img'
    },
    getConfig('Icon')
);

export default Icon;
