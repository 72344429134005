import find from 'lodash/find';
import get from 'lodash/get';
import { getBootstrapData } from '../../swa-bootstrap/index';
import includes from 'lodash/includes';

const environments = ['dev', 'qa', 'ptest', 'cstg', 'efix'];

const getEnv = () => {
    let env;
    let host = window.location.hostname;

    if (host === 'localhost') {
        env = 'local';
    } else if (includes(host, 'nonrev.southwest')) {
        env = 'prod';
    } else {
        env = find(environments, envKey => includes(host, envKey));
    }

    return env;
};

export const getAPIKey = () => {
    const apiKeys = getBootstrapData('swa-bootstrap-nonrev-home-page/api-keys');

    return apiKeys[getEnv()];
};

export const getRequestProps = () => {
    const loginSettings = getBootstrapData('swa-bootstrap-nonrev-home-page/login-settings');

    return {
        clientId: get(loginSettings, 'clientIds.' + getEnv()),
        response_type: get(loginSettings, 'response_type'),
        scope: get(loginSettings, 'scope')
    };
};
