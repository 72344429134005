import compact from 'lodash/compact';
import concat from 'lodash/concat';
import errorTypes from 'data/errorTypes';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { queueLog } from 'logger';

const formatForLogging = (response, errorType, errors) => {
    const callDuration =
        response?.callDurationTimes?.endTime - response?.callDurationTimes?.startTime || 'No callDuration provided';
    const errorList = isArray(errors) ? errors : Array.of(errors);
    const errorProperties = errorTypes[errorType];
    const requestId = response?.requestId || 'No requestId provided';
    const httpCode = response?.status || 'No httpCode provided';
    const responseText = response?.statusText || 'No responseText provided';
    const statusText = response?.statusText || 'No statusText provided';
    const textStatus = response?.error || 'No textStatus provided';
    const url = response?.url || 'No url provided';

    return errorList.map(error => {
        const errorCode = error[errorProperties?.code] || '000000000';
        const message = error[errorProperties?.message] || response?.statusText || 'No message provided';
        const details = {
            callDuration,
            code: errorCode,
            httpCode,
            message,
            requestId,
            responseText,
            statusText,
            textStatus,
            url
        };

        return {
            details,
            errorCode,
            message
        };
    });
};

const transformErrorForLogger = response => {
    const alerts = response?.data?.alertNotification?.errors || undefined;
    const fieldErrors = response?.notifications?.fieldErrors || undefined;
    const formErrors = response?.notifications?.formErrors || undefined;
    const serverErrors = response?.httpStatusCode ? response : undefined;

    const alertErrorLogs = alerts ? formatForLogging(response, 'alert', alerts) : [];
    const fieldErrorLogs = fieldErrors ? formatForLogging(response, 'field', fieldErrors) : [];
    const formErrorLogs = formErrors ? formatForLogging(response, 'form', formErrors) : [];
    const serverErrorLogs = serverErrors ? formatForLogging(response, 'server', serverErrors) : [];

    let errorLogs = compact(concat(alertErrorLogs, fieldErrorLogs, formErrorLogs, serverErrorLogs));

    if (isEmpty(errorLogs)) {
        errorLogs = formatForLogging(response, null, [response]);
    }

    return errorLogs;
};

export const logServiceErrors = (response, pageId) => {
    let logs = transformErrorForLogger(response);

    logs.forEach(log => {
        const { details, errorCode, message } = log;
        const httpCode = response?.status || 'No httpCode provided';

        queueLog('SERVICE_REQUEST', pageId, details, errorCode, httpCode, 'ERROR', message);
    });
};
