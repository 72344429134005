import * as types from './types';

const reducer = (state = {}, action = undefined) => {
    switch (action.type) {
        case types.LOGIN_CLEANUP:
            return { ...state, errorCode: null, loginComplete: false, loginFailed: false, loginProcessing: false };
        case types.LOGIN_USER_FAILED:
            return { ...state, errorCode: action.payload.response.status, loginFailed: true, loginProcessing: false };
        case types.LOGIN_USER_SUCCESS:
            return { ...state, loginComplete: true, loginProcessing: false };
        case types.LOGIN_USER_START:
            return { ...state, loginProcessing: true };
        default:
            return state;
    }
};

export default reducer;
