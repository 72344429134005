import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Shape from './shape';
import Transition from './transition';
import './ripple.scss';

const Ripple = React.memo(props => {
    const DURATION_TRANSITION_0 = 'normal';
    const DURATION_TRANSITION_1 = 'very-slow';
    const CIRCLE_SIZE = 100;

    const [step, setStep] = useState('idle');

    useEffect(() => {
        let newStep = 'idle';

        if (props.phase === 'start') {
            newStep = 'setup';
        }

        setStep(newStep);
    }, [props.phase]);

    const makeIntoPixels = value => {
        return value + 'px';
    };

    const handleTransitionEnd = name => {
        const onTransitionEnd = props.onTransitionEnd;

        if (name === 'ripple-1') {
            if (step === 'setup') {
                setTimeout(() => {
                    setStep('finish');
                }, 1);
            } else {
                setStep('idle');

                if (onTransitionEnd) {
                    onTransitionEnd(name);
                }
            }
        }
    };

    const getClass = () => {
        return classNames('ripple', props.className);
    };

    const getShapeProps = () => {
        return {
            fillColor: props.color,
            height: CIRCLE_SIZE,
            phaseTypes: 'fade',
            shapeType: 'circle',
            speed: 'immediate'
        };
    };

    const getTransitionProps = index => {
        let actions = [
            {
                amount: makeIntoPixels(props.x - CIRCLE_SIZE / 2),
                phase: 'move-X'
            },
            {
                amount: makeIntoPixels(props.y - CIRCLE_SIZE / 2),
                phase: 'move-Y'
            }
        ];

        let newProps = {
            actions: actions,
            className: 'ripple--transition',
            delay: props.delay + index * 0.025,
            name: 'ripple-' + index
        };

        if (step === 'idle' || step === 'setup') {
            actions.push(
                {
                    amount: '0.001',
                    phase: 'scale-X'
                },
                {
                    amount: '0.001',
                    phase: 'scale-Y'
                }
            );
            newProps.speed = 'immediate';

            if (step === 'idle') {
                actions.push({
                    amount: '0',
                    phase: 'opacity'
                });
            } else {
                actions.push({
                    amount: '0.2',
                    phase: 'opacity'
                });
                newProps.onTransitionEnd = handleTransitionEnd;
            }
        } else {
            actions.push(
                {
                    amount: '1.75',
                    phase: 'scale-X'
                },
                {
                    amount: '1.75',
                    phase: 'scale-Y'
                },
                {
                    amount: '0',
                    phase: 'opacity'
                }
            );
            newProps.onTransitionEnd = handleTransitionEnd;
            newProps.speed = index === 0 ? DURATION_TRANSITION_0 : DURATION_TRANSITION_1;
        }

        return newProps;
    };

    const getProps = () => {
        return {
            className: getClass()
        };
    };

    return (
        <span {...getProps()}>
            <Transition {...getTransitionProps(0)}>
                <Shape {...getShapeProps()} />
            </Transition>
            <Transition {...getTransitionProps(1)}>
                <Shape {...getShapeProps()} />
            </Transition>
        </span>
    );
});

Ripple.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'neutral-black',
            'neutral-gray1',
            'neutral-gray2',
            'neutral-gray3',
            'neutral-gray4',
            'neutral-white',
            'primary-blue',
            'primary-blue-darkened',
            'primary-blue-lightened',
            'primary-dark-blue',
            'primary-dark-yellow',
            'primary-light-yellow',
            'primary-red',
            'primary-red-lightened',
            'primary-yellow',
            'secondary-blue',
            'secondary-dark-blue',
            'secondary-gray',
            'secondary-green',
            'secondary-green-lightened',
            'secondary-light-blue',
            'secondary-orange',
            'secondary-teal',
            'secondary-yellow'
        ]),
        PropTypes.string
    ]),
    delay: PropTypes.number,
    onTransitionEnd: PropTypes.func,
    phase: PropTypes.oneOf(['none', 'start']),
    x: PropTypes.number,
    y: PropTypes.number
};

Ripple.defaultProps = {
    color: 'neutral-gray4',
    delay: 0,
    x: 100,
    y: 100
};

export default Ripple;
