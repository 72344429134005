import ControlledInput from 'swa-components-core/controlledInput';
import ControlledPassword from 'swa-components-core/controlledPassword';
import { Field } from 'redux-form';
import i18n from 'i18n/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import Section from 'swa-components-core/section';
import SubmitButton from 'swa-components-core/submitButton';
import './loginForm.style.scss';

const LoginForm = props => {
    const { handleSubmit, invalid, loginProcessing } = props;

    const getProps = () => {
        return {
            className: 'login-form',
            headingContent: i18n('TITLE__LOGIN_FORM_TITLE'),
            headingProps: { className: 'login-form--heading' },
            headingSize: 'extra-small'
        };
    };

    const getUserNameFormControlProps = () => {
        return {
            className: 'login-form--form-control',
            labelText: i18n('LABEL__USERNAME'),
            noLeftMargin: true,
            width: 'full'
        };
    };

    const getUserNameInputProps = () => {
        return {
            autoFocus: true,
            inputType: 'secondary-extra-small',
            maxLength: 10,
            required: true
        };
    };

    const getPasswordFormControlProps = () => {
        return {
            className: 'login-form--form-control',
            labelText: i18n('LABEL__PASSWORD'),
            noLeftMargin: true,
            width: 'full'
        };
    };

    const getPasswordInputProps = () => {
        return {
            inputType: 'secondary-extra-small',
            maxLength: 25,
            required: true
        };
    };

    const getSubmitButtonProps = () => {
        return {
            disabled: invalid || loginProcessing,
            searching: loginProcessing,
            title: i18n('BUTTON__SUBMIT')
        };
    };

    return (
        <Section {...getProps()}>
            <form onSubmit={handleSubmit}>
                <Field
                    name="username"
                    formControlProps={getUserNameFormControlProps()}
                    inputProps={getUserNameInputProps()}
                    component={ControlledInput}
                />
                <Field
                    name="password"
                    formControlProps={getPasswordFormControlProps()}
                    inputProps={getPasswordInputProps()}
                    component={ControlledPassword}
                />
                <div className="login-form--submit">
                    <SubmitButton {...getSubmitButtonProps()}>{i18n('BUTTON__SUBMIT')}</SubmitButton>
                </div>
            </form>
        </Section>
    );
};

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    loginProcessing: PropTypes.bool
};

export default LoginForm;
