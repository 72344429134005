import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import filterDOMProps from '../utils/dom-whitelist/filter-dom-props';
import { getConfig } from './config/config';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './loading.scss';

const Loading = props => {
    useEffect(() => {
        if (props.onLoadingStart) {
            props.onLoadingStart();
        }

        return () => {
            if (props.onLoadingEnd) {
                props.onLoadingEnd();
            }
        };
    });

    const getBoxItemStyles = index => {
        const delay = 3;
        const delayTime = -(delay - index / 10) + 's';

        return {
            WebkitAnimationDelay: delayTime,
            animationDelay: delayTime
        };
    };

    const getBoxItemProps = index => {
        return {
            className: 'loading--box-item',
            key: index,
            style: getBoxItemStyles(index)
        };
    };

    const getClass = () => {
        const classes = {
            loading: true,
            loading_box: props.type === 'box-dark' || props.type === 'box-light',
            'loading_box-dark': props.type === 'box-dark',
            'loading_box-light': props.type === 'box-light',
            'loading_fade-in': props.fadeInDelayEnabled,
            loading_full: props.fullSized,
            loading_large: props.largeSized,
            loading_spinner: props.type === 'spinner'
        };

        return classNames(classes, props.className);
    };

    const getProps = () => {
        let attributes = assignIn({}, props, {
            'aria-label': props.loadingMessage
        });

        if (!props.isLoaded) {
            attributes.className = getClass();
        }

        return filterDOMProps.html(attributes);
    };

    const renderSpinner = () => {
        return (
            <div className="loading--inner-spinner-wrapper">
                <div className="loading--inner-spinner" />
            </div>
        );
    };

    const renderBox = () => {
        const quantity = 5;
        let items = [];
        let boxCount;

        for (boxCount = 0; boxCount < quantity; boxCount += 1) {
            items.push(<div {...getBoxItemProps(boxCount)}></div>);
        }

        return items;
    };

    const searchTypes = {
        'box-dark': renderBox,
        'box-light': renderBox,
        spinner: renderSpinner
    };

    return (
        <div {...getProps()}>
            <div aria-hidden>{searchTypes[props.type]()}</div>
        </div>
    );
};

Loading.propTypes = {
    className: PropTypes.string,
    fadeInDelayEnabled: PropTypes.bool,
    fullSized: PropTypes.bool,
    isLoaded: PropTypes.bool,
    largeSized: PropTypes.bool,
    loadingMessage: PropTypes.string,
    onLoadingEnd: PropTypes.func,
    onLoadingStart: PropTypes.func,
    type: PropTypes.oneOf(['box-dark', 'box-light', 'spinner'])
};

Loading.defaultProps = assignIn(
    {
        fadeInDelayEnabled: true,
        largeSized: false,
        type: 'box-dark'
    },
    getConfig('Loading')
);

export default Loading;
