import Actionable from './actionable';
import assignIn from 'lodash/assignIn';
import browser from '../utils/browserUtils';
import classNames from 'classnames';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Ripple from './ripple';
import './button.scss';

const Button = React.memo(props => {
    const [clickX, setClickX] = useState();
    const [clickY, setClickY] = useState();
    const [showRippleFeedback, setShowRippleFeedback] = useState(false);

    const ref = useRef();

    const getActionableType = () => {
        const buttonTypes = {
            link: 'light',
            'link-body-text-dark': 'body-text-dark',
            'link-body-text-light': 'body-text-light',
            'link-bright': 'bright-base',
            'link-bright-light': 'bright-light',
            'link-bright-simple': 'bright-simple',
            'link-bright-strong': 'bright-strong',
            'link-dark': 'dark',
            'link-dark-simple': 'dark-simple',
            'link-dark-strong': 'dark-strong',
            'link-informational': 'informational',
            'link-light': 'light',
            'link-light-simple': 'light-simple',
            'link-light-strong': 'light-strong'
        };

        return buttonTypes[props.buttonType] || props.buttonType;
    };

    const getAriaSelectedValue = () => {
        return props.childrenRole === 'tab' ? props.selected : null;
    };

    const showRipple = (x, y) => {
        setShowRippleFeedback(true);
        setClickX(x);
        setClickY(y);
    };

    const getButtonBoundingRect = () => {
        return ref.current.getBoundingClientRect();
    };

    const isRippleApplicableToButtonType = () => {
        const applicableButtonTypes = ['primary', 'secondary', 'secondary-dark-affix', 'tertiary', 'tertiary-light'];

        return includes(applicableButtonTypes, props.buttonType);
    };

    const handleClick = event => {
        if (props.onClick) {
            props.onClick(event);
        }
    };

    const handleMouseDown = event => {
        let container;
        let scrollLeft;
        let scrollTop;

        if (isRippleApplicableToButtonType()) {
            container = getButtonBoundingRect();
            scrollLeft = browser.getWindowHorizontalScroll();
            scrollTop = browser.getWindowVerticalScroll();
            showRipple(event.pageX - container.left - scrollLeft, event.pageY - container.top - scrollTop);
        }
    };

    const handleTransitionEnd = () => {
        setShowRippleFeedback(false);
    };

    const getClass = () => {
        const classes = {
            button: true,
            'button_call-to-action': props.callToAction
        };

        return classNames(classes, props.className);
    };

    const getRippleProps = () => {
        let color = 'neutral-gray4';

        if (props.buttonType === 'tertiary-light') {
            color = 'neutral-gray2';
        } else if (props.buttonType === 'primary') {
            color = 'primary-dark-yellow';
        }

        return {
            color: color,
            delay: props.feedbackDelay ? 0.15 : 0,
            onTransitionEnd: handleTransitionEnd,
            phase: showRippleFeedback ? 'start' : 'none',
            x: clickX,
            y: clickY
        };
    };

    const getProps = () => {
        let iconOnly = false;
        let newProps = assignIn({}, props);

        if (props.separator && props['aria-label'] === undefined) {
            if (isString(props.children)) {
                newProps['aria-label'] = props.children;
            } else {
                // TODO: disable this warning for production build
                console.warn('Button: Warning - Separator should has aria-label attribute');
            }
        }

        if (!props.children && (props.prefixIcon || props.suffixIcon)) {
            iconOnly = true;
        }

        newProps['aria-selected'] = getAriaSelectedValue();
        newProps.actionableType = getActionableType();
        newProps.className = getClass();
        newProps.iconOnly = iconOnly;
        newProps.linkSize = null;
        newProps.onClick = handleClick;
        newProps.onMouseDown = handleMouseDown;
        newProps.ref = ref;
        newProps.role = props.separator ? 'separator' : props.role;

        if (props.buttonType === 'close' || props.buttonType === 'close-light') {
            newProps.suffixIcon = 'swa-icon_close';
        }

        if (props.callToAction) {
            newProps.htmlTag = 'span';
        } else if (props.submitButton) {
            newProps.type = 'submit';
        } else {
            newProps.type = 'button';
        }

        return newProps;
    };

    const renderRippleFeedbackEffect = () => {
        let content = null;

        if (isRippleApplicableToButtonType()) {
            content = (
                <span className="button--ripple-effect-container">
                    <Ripple {...getRippleProps()} />
                </span>
            );
        }

        return content;
    };

    return (
        <Actionable {...getProps()}>
            {props.children}
            {renderRippleFeedbackEffect()}
        </Actionable>
    );
});

Button.propTypes = {
    activated: PropTypes.bool,
    'aria-label': PropTypes.string,
    buttonType: PropTypes.string,
    callToAction: PropTypes.bool,
    children: PropTypes.node,
    childrenRole: PropTypes.string,
    className: PropTypes.string,
    feedbackDelay: PropTypes.bool,
    first: PropTypes.bool,
    fullWidthSized: PropTypes.bool,
    largeSized: PropTypes.bool,
    last: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    prefixIcon: PropTypes.string,
    role: PropTypes.string,
    selected: PropTypes.bool,
    separator: PropTypes.bool,
    submitButton: PropTypes.bool,
    suffixIcon: PropTypes.string
};

Button.defaultProps = {
    buttonType: 'primary',
    callToAction: false,
    feedbackDelay: false,
    iconGap: 'medium',
    selected: false
};

export default Button;
