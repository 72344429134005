import { getSession } from 'modules/session';
import { connect } from 'react-redux';
import HomePage from './homePage.component';

const mapStateToProps = (state) => {
    return {
        session: getSession(state)
    };
};

export default connect(mapStateToProps)(HomePage);
