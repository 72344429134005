import errors from './loginPageErrors';
import get from 'lodash/get';
import i18n from 'i18n/i18n';
import Message from 'swa-components-core/message';
import PropTypes from 'prop-types';
import React from 'react';

const LoginPageError = props => {
    const { errorCode } = props;

    const getErrorMessage = () => {
        return i18n(get(errors, errorCode, errors.default));
    };

    const renderErrorMessage = () => {
        let errorMessage = null;

        if (errorCode) {
            errorMessage = <Message messageTitle={getErrorMessage()} type="warning" />;
        }

        return errorMessage;
    };

    return <>{renderErrorMessage()}</>;
};

LoginPageError.propTypes = {
    errorCode: PropTypes.number
};

export default LoginPageError;
