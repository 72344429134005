import FormControl from './formControl';
import Password from 'swa-components-core/password';
import PropTypes from 'prop-types';
import React from 'react';

const ControlledPassword = props => {
    const { formControlProps, input, inputProps } = props;

    return (
        <FormControl {...formControlProps}>
            <Password {...inputProps} {...input} />
        </FormControl>
    );
};

ControlledPassword.propTypes = {
    formControlProps: PropTypes.object,
    input: PropTypes.object,
    inputProps: PropTypes.object
};

export default ControlledPassword;
