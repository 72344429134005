import { bindActionCreators } from 'redux';
import { cleanupLoginState } from 'modules/login';
import { connect } from 'react-redux';
import LoginPage from './loginPage.component';
import { retrievePlacements } from 'modules/placements';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ cleanupLoginState, retrievePlacements }, dispatch);
};

export default connect(null, mapDispatchToProps)(LoginPage);
