import FormControl from './formControl';
import Input from './input';
import PropTypes from 'prop-types';
import React from 'react';

const ControlledInput = props => {
    const { formControlProps, input, inputProps } = props;

    return (
        <FormControl {...formControlProps}>
            <Input {...inputProps} {...input} />
        </FormControl>
    );
};

ControlledInput.propTypes = {
    formControlProps: PropTypes.object,
    input: PropTypes.object,
    inputProps: PropTypes.object
};

export default ControlledInput;