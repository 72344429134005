import { connect } from 'react-redux';
import { getPlacementContentById } from 'modules/placements';
import Background from 'swa-components-core/background';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getPlacementContentById(state, ownProps.placementId)
    };
};

export default connect(mapStateToProps)(Background);
