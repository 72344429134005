import { connect } from 'react-redux';
import { getLoginErrorCode } from 'modules/login';
import LoginPageError from './loginPageError.component';

const mapStateToProps = state => {
    return {
        errorCode: getLoginErrorCode(state)
    };
};

export default connect(mapStateToProps)(LoginPageError);
