import ExternalRedirect from '../externalRedirect';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

const ProtectedRoute = props => {
    const { children, redirectPath, redirectType, shouldRender, ...options } = props;

    const renderRedirect = () => {
        let redirectElement = null;

        if (redirectType === 'INTERNAL') {
            redirectElement = <Redirect to={redirectPath} />;
        } else if (redirectType === 'EXTERNAL') {
            redirectElement = <ExternalRedirect to={redirectPath} />;
        }

        return redirectElement;
    };

    return <Route {...options} render={() => (shouldRender ? children : renderRedirect())} />;
};

ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
    options: PropTypes.shape({
        exact: PropTypes.bool,
        path: PropTypes.string.isRequired
    }),
    redirectPath: PropTypes.string.isRequired,
    redirectType: PropTypes.oneOf(['INTERNAL', 'EXTERNAL']).isRequired,
    shouldRender: PropTypes.bool.isRequired
};

export default ProtectedRoute;
