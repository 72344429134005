const propsHtml = {
    about: true,
    accept: true,
    acceptCharset: true,
    accessKey: true,
    action: true,
    allowFullScreen: true,
    allowTransparency: true,
    alt: true,
    async: true,
    autoCapitalize: true,
    autoComplete: true,
    autoCorrect: true,
    autoPlay: true,
    autoSave: true,
    capture: true,
    cellPadding: true,
    cellSpacing: true,
    challenge: true,
    charSet: true,
    checked: true,
    cite: true,
    classID: true,
    className: true,
    colSpan: true,
    color: true,
    cols: true,
    content: true,
    contentEditable: true,
    contextMenu: true,
    controls: true,
    coords: true,
    crossOrigin: true,
    data: true,
    'data-a': true,
    datatype: true,
    dateTime: true,
    default: true,
    defer: true,
    dir: true,
    disabled: true,
    download: true,
    draggable: true,
    encType: true,
    form: true,
    formAction: true,
    formEncType: true,
    formMethod: true,
    formNoValidate: true,
    formTarget: true,
    frameBorder: true,
    headers: true,
    height: true,
    hidden: true,
    high: true,
    href: true,
    hrefLang: true,
    htmlFor: true,
    httpEquiv: true,
    icon: true,
    id: true,
    inlist: true,
    inputMode: true,
    integrity: true,
    is: true,
    itemID: true,
    itemProp: true,
    itemRef: true,
    itemScope: true,
    itemType: true,
    keyParams: true,
    keyType: true,
    kind: true,
    label: true,
    lang: true,
    list: true,
    loop: true,
    low: true,
    manifest: true,
    marginHeight: true,
    marginWidth: true,
    max: true,
    maxLength: true,
    media: true,
    mediaGroup: true,
    method: true,
    min: true,
    minLength: true,
    multiple: true,
    muted: true,
    name: true,
    noValidate: true,
    nonce: true,
    open: true,
    optimum: true,
    pattern: true,
    placeholder: true,
    poster: true,
    prefix: true,
    preload: true,
    profile: true,
    property: true,
    radioGroup: true,
    readOnly: true,
    rel: true,
    required: true,
    resource: true,
    results: true,
    reversed: true,
    role: true,
    rowSpan: true,
    rows: true,
    sandbox: true,
    scope: true,
    scoped: true,
    scrolling: true,
    seamless: true,
    security: true,
    selected: true,
    shape: true,
    size: true,
    sizes: true,
    span: true,
    spellCheck: true,
    src: true,
    srcDoc: true,
    srcLang: true,
    srcSet: true,
    start: true,
    step: true,
    style: true,
    summary: true,
    tabIndex: true,
    target: true,
    title: true,
    type: true,
    typeof: true,
    unselectable: true,
    useMap: true,
    value: true,
    vocab: true,
    width: true,
    wmode: true,
    wrap: true
};

export default propsHtml;
