import classNames from 'classnames';
import HorizontalRule from 'swa-components-core/horizontalRule';
import i18n from 'i18n/i18n';
import LoginForm from 'components/loginForm';
import LoginPageError from 'components/loginPageError';
import PropTypes from 'prop-types';
import React from 'react';
import Section from 'swa-components-core/section';
import './loginSidebar.style.scss';

const propTypes = {
    className: PropTypes.string
};

const LoginSidebar = props => {
    const { className } = props;

    const getClass = () => {
        const classes = {
            [className]: className,
            'login-sidebar': true
        };

        return classNames(classes);
    };

    const getSectionProps = () => {
        return {
            className: 'login-sidebar--section',
            headingContent: i18n('TITLE__LOGIN_TITLE'),
            headingProps: { className: 'login-sidebar--heading' },
            headingSize: 'medium'
        };
    };

    const getHorizontalRuleProps = () => {
        return {
            className: 'login-sidebar--horizontal-rule'
        };
    };

    return (
        <section className={getClass()}>
            <HorizontalRule {...getHorizontalRuleProps()} />
            <Section {...getSectionProps()}>
                <LoginPageError />
                <LoginForm />
            </Section>
            <HorizontalRule {...getHorizontalRuleProps()} />
        </section>
    );
};

LoginSidebar.propTypes = propTypes;

export default LoginSidebar;
