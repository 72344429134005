import ExternalRedirect from 'components/externalRedirect';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TitleManager from 'swa-components-core/titleManager';
import userRoles from '../../defines/scope';

const propTypes = {
    session: PropTypes.shape({
        scope: PropTypes.string.isRequired
    })
};

export const HomePage = (props) => {
    const { session } = props;
    const { AuthorizedBookingSpecialist, CoBusinessSelfService, NRSA, NRSASupportRole, TravelServices } = userRoles;
    const origin = window.location.origin;
    const [appType, setAppType] = useState();

    useEffect(() => {
        /* istanbul ignore else */
        if (session) {
            const roles = session.scope.split(' ');

            /* istanbul ignore else */
            if (roles.includes(NRSA) || roles.includes(NRSASupportRole)) {
                setAppType('spaceavailable');
            } else if (
                roles.includes(CoBusinessSelfService) ||
                roles.includes(TravelServices) ||
                roles.includes(AuthorizedBookingSpecialist)
            ) {
                setAppType('mustride');
            }
        }
    }, [session]);

    return (
        <>
            <TitleManager />
            {appType === 'mustride' && <ExternalRedirect to={`${origin}/air/booking/`} />}
            {appType === 'spaceavailable' && <ExternalRedirect to={`${origin}/air/space-available/`} />}
        </>
    );
};

HomePage.propTypes = propTypes;

export default HomePage;
