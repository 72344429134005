import { i18nGlobal } from './global';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';

const findMessageFunction = key => {
    return i18nGlobal[key];
};

const processArray = (key, data) => {
    let messages = [];

    key.forEach(aKey => {
        let msgFunction = findMessageFunction(aKey);

        if (msgFunction) {
            messages.push(msgFunction(data));
        } else {
            messages.push(aKey);
        }
    });

    return messages;
};

const processSingleKey = (key, data) => {
    let msgFunc = findMessageFunction(key);

    if (!isUndefined(msgFunc)) {
        try {
            return msgFunc(data);
        } catch (error) {
            setTimeout(() => {
                throw error;
            }, 0);
        }
    }

    return key;
};

const i18n = (key, data) => {
    if (isArray(key)) {
        return processArray(key, data);
    } else {
        return processSingleKey(key, data);
    }
};

export default i18n;
