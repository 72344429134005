import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storageSession from 'redux-persist/lib/storage/session';

const reconcileState = persistOverrides => {
    return (inboundState, originalState, reducedState) => {
        let finalState;

        if (persistOverrides) {
            finalState = {
                ...autoMergeLevel1(inboundState, originalState, reducedState, { debug: false }),
                ...persistOverrides
            };
        } else {
            finalState = autoMergeLevel1(inboundState, originalState, reducedState, { debug: false });
        }

        return finalState;
    };
};

export const getPersistConfig = (config, overrides) => {
    return {
        key: 'southwest',
        stateReconciler: reconcileState(overrides),
        storage: storageSession,
        whitelist: [],
        ...config
    };
};
