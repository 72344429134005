import location from '../utils/locationUtils';

let config = {};
let defaultMaxMessagesToQueue = 20;
let defaultReportInterval = 15000;
let defaultUuid = 'No UUID provided';
let messagesToSend = {};

const sendMessages = () => {
    let messages = [];

    if (Object.keys(messagesToSend).length > 0) {
        Object.keys(messagesToSend).forEach(key => {
            messages.push(messagesToSend[key]);
            delete messagesToSend[key];
        });

        if (config.loggerCallback) {
            config.loggerCallback(messages);
        }
    }
};

const getConfigKey = (key, defaultValue) => {
    let value = defaultValue;
    let configValue;

    for (configValue in config) {
        if (configValue === key) {
            value = config[key];
        }
    }

    return value;
};

const getMaxMessagesToQueue = () => {
    let maxMessagesToQueue = getConfigKey('maxMessagesToQueue', defaultMaxMessagesToQueue);

    return maxMessagesToQueue;
};

const getReportInterval = () => {
    let interval = getConfigKey('reportInterval', defaultReportInterval);

    return interval;
};

const queueLog = (action, component, details, errorCode, httpCode, level, message) => {
    const messageKey = component + errorCode;

    if (Object.keys(messagesToSend).length < getMaxMessagesToQueue()) {
        if (messagesToSend[messageKey] === undefined) {
            messagesToSend[messageKey] = {
                action: action,
                component: component ? `nonrev-${component}` : 'nonrev',
                count: 1,
                details: JSON.stringify({
                    ...details,
                    experienceId: getConfigKey('uuid', defaultUuid)
                }),
                errorCode: errorCode ? errorCode.toString() : '000000000',
                httpCode: httpCode ? httpCode.toString() : '000',
                level: level,
                location: `/nonrev/home-page${location.getPathname()}`,
                message: message
            };
        } else {
            messagesToSend[messageKey].count += 1;
        }
    }
};

const logGlobalErrorEvent = event => {
    const eventErrorCode = event.lineno ? event.lineno.toString() : '0';

    queueLog(
        'UNCAUGHT ERROR',
        'globalErrorEvent',
        {
            colno: event.colno,
            error: event.error,
            lineno: event.lineno,
            message: event.message,
            source: event.filename
        },
        eventErrorCode
    );
};

const logGlobalError = (message, source, lineno, colno, error) => {
    const errorCode = lineno ? lineno.toString() : '000000000';

    queueLog('UNCAUGHT ERROR', 'globalError', { colno, error, lineno, message, source }, errorCode);
};

const initializeLogger = appConfig => {
    window.onerror = logGlobalError;
    window.addEventListener('error', logGlobalErrorEvent, { capture: true });

    config = appConfig;
    const reportInterval = getReportInterval();

    const run = () => {
        sendMessages();
        setTimeout(run, reportInterval);
    };

    setTimeout(run, reportInterval);
};

export default initializeLogger;
export { queueLog };
