import { CLEANUP_PLACEMENT_STATE, RETRIEVE_PLACEMENTS_FAILURE, RETRIEVE_PLACEMENTS_SUCCESS } from './types';
import isUndefined from 'lodash/isUndefined';

const initialState = {};

const placementsReducer = (state, action) => {
    if (isUndefined(state)) {
        return initialState;
    }

    switch (action.type) {
        case CLEANUP_PLACEMENT_STATE:
            return initialState;
        case RETRIEVE_PLACEMENTS_FAILURE:
            return { ...state, ...action.payload.results };
        case RETRIEVE_PLACEMENTS_SUCCESS:
            return { ...state, ...action.payload.results };
        default:
            return state;
    }
};

export default placementsReducer;
