import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLoginProcessing, login } from 'modules/login';
import LoginForm from './loginForm.component';
import { reduxForm } from 'redux-form';
import { validate } from './loginForm.validators';

const mapStateToProps = state => {
    return {
        loginForm: state.form['login-form'],
        loginProcessing: getLoginProcessing(state)
    };
};

const mapDispatchToProps = dispatch => {
    let creators = bindActionCreators({ login }, dispatch);

    return {
        onSubmit: creators.login
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: 'login-form', validate })(LoginForm));
