import { connect } from 'react-redux';
import { getExternalId } from 'modules/external';
import { getHeaderProps } from 'modules/systemData';
import LoggerManager from './loggerManager.component';

const mapStateToProps = state => {
    const headerProps = {
        ...getHeaderProps(state),
        contentType: 'application/json',
        uuid: getExternalId(state)
    };

    return {
        headerProps: headerProps
    };
};

export default connect(mapStateToProps)(LoggerManager);
