import LoginSidebar from 'components/loginSidebar';
import PlacementBackground from 'components/placementBackground';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TitleManager from 'swa-components-core/titleManager';
import './loginPage.style.scss';

const propTypes = {
    cleanupLoginState: PropTypes.func,
    loginComplete: PropTypes.bool,
    retrievePlacements: PropTypes.func
};

export const LoginPage = (props) => {
    const { cleanupLoginState, retrievePlacements } = props;

    useEffect(() => {
        retrievePlacements('nonrev-home-page-login');

        return () => {
            cleanupLoginState();
        };
    }, [cleanupLoginState]);

    const placementBackgroundProps = {
        className: 'login-page--background',
        hasOffsetBottom: false,
        hasOffsetTop: false,
        placementId: 'nonrevLoginBackground'
    };

    return (
        <>
            <TitleManager pageTitleKey="PAGE_TITLE__LANDING_HOME_PAGE_LOGIN" />
            <div className="login-page">
                <PlacementBackground {...placementBackgroundProps}>
                    <LoginSidebar className="login-page--sidebar" />
                </PlacementBackground>
            </div>
        </>
    );
};

LoginPage.propTypes = propTypes;

export default LoginPage;
