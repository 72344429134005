import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './horizontalRule.scss';

const HorizontalRule = props => {

    const getClass = () => {
        return classNames('horizontal-rule', props.className);
    };

    const getProps = () => {
        return {
            'aria-hidden': 'true',
            className: getClass()
        };
    };

    return (
        <hr {...getProps()} />
    );    
};

HorizontalRule.propTypes = {
    className: PropTypes.string
};

export default HorizontalRule;
