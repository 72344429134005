export const validate = values => {
    const {password, username} = values;

    const errors = {};

    if (!username) {
        errors.username = true;
    }

    if (!password) {
        errors.password = true;
    }

    return errors;
};
