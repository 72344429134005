import React from 'react';
import PropTypes from 'prop-types';

export const ExternalRedirect = props => {
    const { to } = props;

    const renderExternalRedirect = () => {
        window.location.href = to;

        return null;
    };

    return <>{renderExternalRedirect()}</>;
};

ExternalRedirect.propTypes = {
    to: PropTypes.string.isRequired
};

export default ExternalRedirect;
