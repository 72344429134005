import classNames from 'classnames';
import indexOf from 'lodash/indexOf';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';
import './transition.scss';

const Transition = props => {
    const getSpeed = modifier => {
        const speeds = ['immediate', 'very-fast', 'fast', 'normal', 'slow', 'very-slow'];
        const speedValues = ['0.00001s', '0.175s', '0.3125s', '0.45s', '0.675s', '0.9s'];
        let speed = props.speed;
        let index = indexOf(speeds, speed);

        if (index !== -1) {
            if (modifier === 'faster' && index > 0) {
                index -= 1;
            }

            if (modifier === 'slower' && index < speeds.length - 1) {
                index += 1;
            }

            speed = speedValues[index];
        }

        return speed;
    };

    const getStyle = () => {
        const actions = props.actions;
        const delay = props.delay;
        const speed = getSpeed();
        const transformActions = {
            'move-X': 'translateX(',
            'move-Y': 'translateY(',
            'move-Z': 'translateZ(',
            'rotate-X': 'rotateX(',
            'rotate-Y': 'rotateY(',
            'rotate-Z': 'rotateZ(',
            'scale-X': 'scaleX(',
            'scale-Y': 'scaleY(',
            'scale-Z': 'scaleZ('
        };
        let transforms = [];
        let opacity;
        let phase;
        let style;
        let transition = '';

        if (actions) {
            actions.forEach(action => {
                phase = action.phase;

                if (phase !== 'none') {
                    if (phase === 'opacity') {
                        opacity = action.amount;
                    } else {
                        transforms.push(transformActions[phase] + action.amount + ')');
                    }
                }
            });

            if (opacity !== undefined || transforms.length) {
                style = {};

                if (opacity !== undefined) {
                    style.opacity = opacity;
                    transition = 'opacity ' + speed + ' ease-in-out ' + (delay !== undefined ? delay + 's' : '');
                }

                if (transforms.length) {
                    if (transition.length) {
                        transition += ', ';
                    }

                    transition += 'transform ' + speed + ' ease-in-out ' + (delay !== undefined ? delay + 's' : '');
                    style.transform = transforms.join(' ');
                }

                if (transition !== '') {
                    style.transition = transition;
                }
            }
        }

        return style;
    };

    const handleTransitionEnd = event => {
        const onTransitionEnd = props.onTransitionEnd;

        event.stopPropagation();
        event.preventDefault();

        if (onTransitionEnd) {
            onTransitionEnd(props.name);
        }
    };

    const getClass = () => {
        const transformOrigin = props.transformOrigin;

        return classNames(
            {
                transition: true,
                'transition--origin-left-top': transformOrigin === 'leftTop',
                'transition--origin-right-bottom': transformOrigin === 'rightBottom'
            },
            props.className
        );
    };
    const getProps = () => {
        const style = getStyle();
        let attributes = {
            className: getClass(),
            onTransitionEnd: handleTransitionEnd
        };

        if (style) {
            attributes.style = style;
        }

        return attributes;
    };

    const renderChildren = () => {
        const children = props.children;

        return isFunction(children) ? children() : children;
    };

    return <div {...getProps()}>{renderChildren()}</div>;
};

Transition.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            onTransitionEnd: PropTypes.func,
            phase: PropTypes.oneOf([
                'opacity',
                'move-X',
                'move-Y',
                'move-Z',
                'none',
                'rotate-X',
                'rotate-Y',
                'rotate-Z',
                'scale-X',
                'scale-Y',
                'scale-Z'
            ])
        })
    ),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
    delay: PropTypes.number,
    name: PropTypes.string,
    onTransitionEnd: PropTypes.func,
    speed: PropTypes.oneOfType([
        PropTypes.oneOf(['fast', 'immediate', 'normal', 'slow', 'very-fast', 'very-slow']),
        PropTypes.string
    ]),
    transformOrigin: PropTypes.oneOf(['leftTop', 'rightBottom'])
};

Transition.defaultProps = {
    speed: 'normal'
};

export default Transition;
