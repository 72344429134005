import assignIn from 'lodash/assignIn';
import Button from './button';
import { getConfig } from './config/config';
import PropTypes from 'prop-types';
import React from 'react';

const ShowPasswordButton = props => {
    const getButtonClass = () => {
        return props.disabled ? 'swa-g-disabled' : '';
    };

    const getProps = () => {
        return {
            'aria-label': props.passwordIsShown ? props.ariaLabelHideButton : props.ariaLabelShowButton,
            buttonType: 'link',
            className: getButtonClass(),
            onClick: props.onClick
        };
    };

    const renderButtonText = () => {
        return props.passwordIsShown ? props.hidePasswordButtonText : props.showPasswordButtonText;
    };

    return (
        <div className="show-password-button">
            <Button {...getProps()}>{renderButtonText()}</Button>
        </div>
    );
};

ShowPasswordButton.propTypes = {
    ariaLabelHideButton: PropTypes.string,
    ariaLabelShowButton: PropTypes.string,
    disabled: PropTypes.bool,
    hidePasswordButtonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    passwordIsShown: PropTypes.bool,
    showPasswordButtonText: PropTypes.string.isRequired
};

ShowPasswordButton.defaultProps = assignIn(
    {},
    {
        disabled: false,
        passwordIsShown: false
    },
    getConfig('ShowPasswordButton')
);

export default ShowPasswordButton;
