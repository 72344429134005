import assignIn from 'lodash/assignIn';
import Button from './button';
import classNames from 'classnames';
import { getConfig } from './config/config';
import Loading from './loading';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import './submitButton.scss';

const SubmitButton = props => {
    const isSearching = () => {
        return props.searching;
    };

    const getAriaLabel = () => {
        let ariaLabel = props['aria-label'] || props.children;

        if (isSearching()) {
            ariaLabel = props.loadingMessage;
        }

        return ariaLabel;
    };

    const getLoadingClass = () => {
        const classes = {
            'submit-button--loading': true,
            'submit-button--loading_dark': props.loadingType === 'box-dark',
            'submit-button--loading_light': props.loadingType === 'box-light'
        };

        return classNames(classes);
    };

    const getClass = () => {
        let classes = {
            'submit-button': true,
            'submit-button_searching': props.searching,
            'swa-g-disabled': props.searching || props.disabled
        };

        classes[props.className] = props.className;

        return classNames(classes);
    };

    const getProps = () => {
        let attributes = assignIn({}, props, {
            'aria-label': getAriaLabel(),
            className: getClass(),
            feedbackDelay: true,
            submitButton: true
        });

        if (isSearching()) {
            attributes.disabled = true;
            attributes = omit(attributes, ['prefixIcon']);
        }

        return attributes;
    };

    const renderLoading = () => {
        const loadingProps = {
            className: getLoadingClass(),
            fadeInDelayEnabled: false,
            type: props.loadingType
        };
        let loading = null;

        if (isSearching()) {
            loading = (
                <div className="submit-button--loading-container">
                    <Loading {...loadingProps} />
                </div>
            );
        }

        return loading;
    };

    return (
        <Button {...getProps()}>
            <span className="submit-button--text">{props.children}</span>
            {renderLoading()}
        </Button>
    );
};

SubmitButton.propTypes = {
    'aria-label': PropTypes.string,
    buttonType: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    largeSized: PropTypes.bool,
    loadingMessage: PropTypes.string,
    loadingType: PropTypes.oneOf(['box-dark', 'box-light']),
    searching: PropTypes.bool
};

SubmitButton.defaultProps = assignIn(
    {
        buttonType: 'primary',
        disabled: false,
        largeSized: false,
        loadingType: 'box-dark',
        searching: false
    },
    getConfig('SubmitButton')
);

export default SubmitButton;
