import * as types from '../types';
import { change } from 'redux-form';
import { getExternalId } from 'modules/external';
import { getHeaderProps, getRequestProps } from 'modules/systemData';
import { logServiceErrors } from 'utils/transformErrorForLogger';
import dayjs from 'dayjs';
import { post } from 'api/serviceCaller';

const getUnixExpiration = sessionDuration => {
    return dayjs()
        .add(sessionDuration, 's')
        .unix();
};

const startLogin = () => {
    return {
        type: types.LOGIN_USER_START
    };
};

const loginSuccess = response => {
    response['expiration'] = getUnixExpiration(response.expires_in);

    return {
        payload: response,
        type: types.LOGIN_USER_SUCCESS
    };
};

const loginFailure = error => {
    return {
        payload: error,
        type: types.LOGIN_USER_FAILED
    };
};

export const login = values => {
    return async (dispatch, getState) => {
        const body = {
            password: values.password,
            username: values.username?.trim()
        };
        const currentState = getState();
        const options = {
            headerProps: {
                ...getHeaderProps(currentState),
                contentType: 'application/x-www-form-urlencoded',
                uuid: getExternalId(currentState)
            },
            requestProps: getRequestProps(currentState)
        };

        dispatch(startLogin());

        try {
            dispatch(loginSuccess(await post('login', body, options)));
        } catch (error) {
            if (error.response) {
                dispatch(change('login-form', 'password', ''));
                dispatch(loginFailure(error));
                logServiceErrors(error.response, 'login');
            } else {
                setTimeout(() => {
                    throw error;
                }, 0);
            }
        }
    };
};
