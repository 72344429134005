import i18n from '../i18n/i18n';
import location from '../utils/locationUtils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const TitleManager = props => {
    const { pageTitleKey } = props;

    const getPageTitle = () => {
        let key = pageTitleKey;

        if (!pageTitleKey) {
            const pageID = location.getPageIdentifier();
            const formattedPageID = pageID.replace(/-/g, '_');

            key = `PAGE_TITLE__${formattedPageID.toUpperCase()}`;
        }

        return i18n(key);
    };

    useEffect(() => {
        document.title = getPageTitle();
    });

    return <></>;
};

TitleManager.propTypes = {
    pageTitleKey: PropTypes.string
};

export default TitleManager;
