import loginReducer from 'modules/login';
import placementsReducer from '../../modules/placements';
import { reducer as formReducer } from 'redux-form';
import sessionReducer from 'modules/session';
import systemDataReducer from 'modules/systemData';

const reducerConfig = {
    form: formReducer,
    loginState: loginReducer,
    placements: placementsReducer,
    session: sessionReducer,
    systemData: systemDataReducer
};

export default reducerConfig;
