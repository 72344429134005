const propsSvg = {
    accentHeight: true,
    accumulate: true,
    additive: true,
    alignmentBaseline: true,
    allowReorder: true,
    alphabetic: true,
    amplitude: true,
    arabicForm: true,
    ascent: true,
    attributeName: true,
    attributeType: true,
    autoReverse: true,
    azimuth: true,
    baseFrequency: true,
    baseProfile: true,
    baselineShift: true,
    bbox: true,
    begin: true,
    bias: true,
    by: true,
    calcMode: true,
    capHeight: true,
    className: true,
    clip: true,
    clipPath: true,
    clipPathUnits: true,
    clipRule: true,
    color: true,
    colorInterpolation: true,
    colorInterpolationFilters: true,
    colorProfile: true,
    colorRendering: true,
    contentScriptType: true,
    contentStyleType: true,
    cursor: true,
    cx: true,
    cy: true,
    d: true,
    decelerate: true,
    descent: true,
    diffuseConstant: true,
    direction: true,
    display: true,
    divisor: true,
    dominantBaseline: true,
    dur: true,
    dx: true,
    dy: true,
    edgeMode: true,
    elevation: true,
    enableBackground: true,
    end: true,
    exponent: true,
    externalResourcesRequired: true,
    fill: true,
    fillOpacity: true,
    fillRule: true,
    filter: true,
    filterRes: true,
    filterUnits: true,
    floodColor: true,
    floodOpacity: true,
    focusable: true,
    fontFamily: true,
    fontSize: true,
    fontSizeAdjust: true,
    fontStretch: true,
    fontStyle: true,
    fontVariant: true,
    fontWeight: true,
    format: true,
    from: true,
    fx: true,
    fy: true,
    g1: true,
    g2: true,
    glyphName: true,
    glyphOrientationHorizontal: true,
    glyphOrientationVertical: true,
    glyphRef: true,
    gradientTransform: true,
    gradientUnits: true,
    hanging: true,
    height: true,
    horizAdvX: true,
    horizOriginX: true,
    id: true,
    ideographic: true,
    imageRendering: true,
    in: true,
    in2: true,
    intercept: true,
    k: true,
    k1: true,
    k2: true,
    k3: true,
    k4: true,
    kernelMatrix: true,
    kernelUnitLength: true,
    kerning: true,
    keyPoints: true,
    keySplines: true,
    keyTimes: true,
    lang: true,
    lengthAdjust: true,
    letterSpacing: true,
    lightingColor: true,
    limitingConeAngle: true,
    local: true,
    markerEnd: true,
    markerHeight: true,
    markerMid: true,
    markerStart: true,
    markerUnits: true,
    markerWidth: true,
    mask: true,
    maskContentUnits: true,
    maskUnits: true,
    mathematical: true,
    max: true,
    media: true,
    method: true,
    min: true,
    mode: true,
    name: true,
    numOctaves: true,
    offset: true,
    opacity: true,
    operator: true,
    order: true,
    orient: true,
    orientation: true,
    origin: true,
    overflow: true,
    overlinePosition: true,
    overlineThickness: true,
    paintOrder: true,
    panose1: true,
    pathLength: true,
    patternContentUnits: true,
    patternTransform: true,
    patternUnits: true,
    pointerEvents: true,
    points: true,
    pointsAtX: true,
    pointsAtY: true,
    pointsAtZ: true,
    preserveAlpha: true,
    preserveAspectRatio: true,
    primitiveUnits: true,
    r: true,
    radius: true,
    refX: true,
    refY: true,
    renderingIntent: true,
    repeatCount: true,
    repeatDur: true,
    requiredExtensions: true,
    requiredFeatures: true,
    restart: true,
    result: true,
    role: true,
    rotate: true,
    rx: true,
    ry: true,
    scale: true,
    seed: true,
    shapeRendering: true,
    slope: true,
    spacing: true,
    specularConstant: true,
    specularExponent: true,
    speed: true,
    spreadMethod: true,
    startOffset: true,
    stdDeviation: true,
    stemh: true,
    stemv: true,
    stitchTiles: true,
    stopColor: true,
    stopOpacity: true,
    strikethroughPosition: true,
    strikethroughThickness: true,
    string: true,
    stroke: true,
    strokeDasharray: true,
    strokeDashoffset: true,
    strokeLinecap: true,
    strokeLinejoin: true,
    strokeMiterlimit: true,
    strokeOpacity: true,
    strokeWidth: true,
    style: true,
    surfaceScale: true,
    systemLanguage: true,
    tableValues: true,
    target: true,
    targetX: true,
    targetY: true,
    textAnchor: true,
    textDecoration: true,
    textLength: true,
    textRendering: true,
    to: true,
    transform: true,
    type: true,
    u1: true,
    u2: true,
    underlinePosition: true,
    underlineThickness: true,
    unicode: true,
    unicodeBidi: true,
    unicodeRange: true,
    unitsPerEm: true,
    vAlphabetic: true,
    vHanging: true,
    vIdeographic: true,
    vMathematical: true,
    values: true,
    vectorEffect: true,
    version: true,
    vertAdvY: true,
    vertOriginX: true,
    vertOriginY: true,
    viewBox: true,
    viewTarget: true,
    visibility: true,
    width: true,
    widths: true,
    wordSpacing: true,
    writingMode: true,
    x: true,
    x1: true,
    x2: true,
    xChannelSelector: true,
    xHeight: true,
    xlinkActuate: true,
    xlinkArcrole: true,
    xlinkHref: true,
    xlinkRole: true,
    xlinkShow: true,
    xlinkTitle: true,
    xlinkType: true,
    xmlBase: true,
    xmlLang: true,
    xmlSpace: true,
    y: true,
    y1: true,
    y2: true,
    yChannelSelector: true,
    z: true,
    zoomAndPan: true
};

export default propsSvg;
