import appConfig from 'applicationConfig/storeConfig/appConfig';
import { getAPIKey, getRequestProps } from 'applicationConfig/storeConfig/apiKeyConfig';
import reducerConfig from 'applicationConfig/storeConfig/reducerConfig';

export default {
    initialState: {
        systemData: {
            api: {
                headerProps: {
                    apiKey: getAPIKey(),
                    appId: appConfig.appId,
                    contentType: 'application/json'
                },
                requestProps: {
                    ...getRequestProps()
                }
            },
            appConfig: {
                ...appConfig
            }
        }
    },
    persistConfig: {
        key: 'nonrev',
        whitelist: ['session']
    },
    reducerConfig: reducerConfig
};
