import assignIn from 'lodash/assignIn';
import Button from './button';
import classNames from 'classnames';
import { getConfig } from './config/config';
import Icon from './icon';
import includes from 'lodash/includes';
import keyCode from 'swa-input/key-code';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
// import layerStore from "./layer/layerStore";
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ShowPasswordButton from './showPasswordButton';
import './input.scss';

const Input = React.memo(props => {
    const DEFAULT_ICON_SIZE = 18;
    const inputRef = useRef();
    const iconRef = useRef();
    const [type, setType] = useState(props.type);

    const isValueShown = () => {
        return (!props.blocked && !props.disabled) || !props.disabled || props.readOnly;
    };

    const isSecondaryInputType = () => {
        const inputType = props.inputType;

        return inputType === 'secondary' || inputType === 'secondary-extra-small' || inputType === 'secondary-small';
    };

    const isTypePassword = () => {
        return type === 'password';
    };

    // const isAutocompleteOpen = () => {
    // return get(layerStore.getUpperLayerFlyout(), "props.autocomplete");
    // };

    const isActionKey = event => {
        const actionKeys = [keyCode.BACKSPACE, keyCode.DELETE, keyCode.ENTER, keyCode.ESCAPE, keyCode.TAB];
        const controlMetaKeyPressed = event.ctrlKey || event.metaKey;
        const keyPressed = event.which;

        return (
            includes(actionKeys, keyPressed) ||
            (keyPressed === keyCode.A && controlMetaKeyPressed) ||
            (keyPressed === keyCode.C && controlMetaKeyPressed) ||
            (keyPressed === keyCode.X && controlMetaKeyPressed) ||
            keyPressed === keyCode.END ||
            keyPressed === keyCode.HOME ||
            keyPressed === keyCode.LEFT ||
            keyPressed === keyCode.RIGHT
        );
    };

    const isNumberKey = event => {
        const keyPressed = event.which;
        let numberKey = true;

        if (
            (event.shiftKey || (keyPressed < keyCode.ZERO || keyPressed > keyCode.NINE)) &&
            (keyPressed < keyCode.NUMPAD_ZERO || keyPressed > keyCode.NUMPAD_NINE)
        ) {
            numberKey = false;
        }

        return numberKey;
    };

    const shouldUseLargeSuffixTextContainer = () => {
        return props.suffixTextLength && isValueShown() && props.value.length < 8;
    };

    const getInputNode = () => {
        return inputRef.current;
    };

    const getSuffixText = () => {
        const inputValue = props.value;
        const suffixTextLength = props.suffixTextLength;
        let suffixText = '';

        if (suffixTextLength && isValueShown()) {
            suffixText = inputValue.substr(inputValue.length - suffixTextLength, inputValue.length);
        }

        return suffixText;
    };

    const selectAllText = () => {
        const inputNode = getInputNode();
        const end = inputNode.value.length;
        const start = 0;
        let range;

        if (inputNode === document.activeElement) {
            if (inputNode.setSelectionRange) {
                inputNode.setSelectionRange(start, end);
            } else if (inputNode.createTextRange) {
                range = inputNode.createTextRange();
                range.moveEnd('character', end);
                range.moveStart('character', start);
                range.select();
            }
        }
    };

    const restrictToNumbersOnly = event => {
        if (!isActionKey(event) && !isNumberKey(event)) {
            event.preventDefault();
        }
    };

    const focus = () => {
        getInputNode().focus();
    };

    const handleIconClick = event => {
        event.preventDefault();
    };

    const handleIconSeparatorClick = () => {
        focus();
    };

    const handleIconSeparatorFocus = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleIconSeparatorMouseDown = () => {
        // if (!isAutocompleteOpen()) {
        //     event.preventDefault();
        // }

        if (props.onMouseDown) {
            props.onMouseDown();
        }
    };

    const handleShowHidePassword = () => {
        setType(isTypePassword() ? 'text' : 'password');
    };

    const handleRemoveClick = () => {
        if (props.onChange) {
            props.onChange({
                target: {
                    value: ''
                }
            });
        }

        focus();
    };

    const handleFocus = event => {
        if (props.selectAllOnFocus) {
            selectAllText();
        }

        if (props.onFocus) {
            props.onFocus(event);
        }
    };

    const handleKeyDown = event => {
        const keyDown = props.keyDown;

        restrictToNumbersOnly(event);

        if (keyDown) {
            keyDown(event);
        }
    };

    const getInputClassName = () => {
        return classNames({
            'input--text': true,
            'input_error-remove': props.error && !props.disabled && props.showRemoveOption,
            input_remove: !props.error && props.showRemoveOption
        });
    };

    const getShowPasswordButtonClass = () => {
        const classes = {
            'input--show-password-button': true,
            'input--show-password-button_horizontal-offset': props.error
        };

        return classNames(classes);
    };

    const getRemoveButtonContainerClass = () => {
        const classes = {
            'input--remove-button': true,
            'input--remove-button_horizontal-offset': props.error && !props.disabled
        };

        return classNames(classes);
    };

    const getRemoveButtonClass = () => {
        return classNames({
            'swa-g-disabled': props.disabled || props.blocked
        });
    };

    const getIconClass = () => {
        const classes = {
            'input--icon': true,
            'input--icon_dark': props.iconDark,
            'input--icon_error': props.error
        };

        return classNames(classes);
    };

    const getClass = () => {
        const inputType = props.inputType;

        const classes = {
            input: true,
            input_center: props.textAlign === 'center',
            input_error: props.error && !props.disabled,
            input_icon: props.suffixIcon || (props.error && !props.showRemoveOption),
            input_left: props.textAlign === 'left',
            'input_number-formatted': props.numberFormatted,
            input_primary: inputType === 'primary',
            'input_primary-simple': inputType === 'primary-simple',
            'input_read-only': props.readOnly,
            input_right: props.textAlign === 'right',
            input_secondary: isSecondaryInputType(),
            'input_secondary-extra-small': inputType === 'secondary-extra-small',
            'input_secondary-simple': inputType === 'secondary-simple',
            'input_secondary-small': inputType === 'secondary-small',
            'input_suffix-container-large': shouldUseLargeSuffixTextContainer(),
            input_tertiary: inputType === 'tertiary',
            'input_with-suffix-text': props.suffixTextLength,
            'swa-g-disabled': props.disabled || props.blocked
        };

        return classNames(classes, props.className);
    };

    const getIconSeparatorProps = () => {
        return {
            className: 'input--icon-separator',
            onClick: props.onIconSeparatorClick || handleIconSeparatorClick,
            onFocus: handleIconSeparatorFocus,
            onMouseDown: handleIconSeparatorMouseDown
        };
    };

    const getShowPasswordButtonProps = () => {
        return {
            disabled: props.disabled || props.blocked,
            onClick: handleShowHidePassword,
            passwordIsShown: !isTypePassword()
        };
    };

    const getRemoveButtonProps = () => {
        return {
            'aria-label': props.ariaLabelRemoveButton,
            buttonType: 'link',
            className: getRemoveButtonClass(),
            onBlur: props.onBlur || noop,
            onClick: handleRemoveClick
        };
    };

    const getIconProps = () => {
        return {
            className: getIconClass(),
            icon: props.error && !props.disabled ? 'swa-icon_error' : props.suffixIcon,
            onClick: handleIconClick,
            ref: iconRef,
            size: props.inputType === 'secondary' ? DEFAULT_ICON_SIZE : props.iconSize
        };
    };

    const getProps = () => {
        const inputWhiteList = [
            'aria-activedescendant',
            'aria-autocomplete',
            'aria-controls',
            'aria-describedby',
            'aria-expanded',
            'aria-label',
            'aria-labelledby',
            'aria-owns',
            'aria-valuemax',
            'aria-valuemin',
            'aria-valuenow',
            'autoCapitalize',
            'autoComplete',
            'autoCorrect',
            'autoFocus',
            'defaultValue',
            'id',
            'maxLength',
            'name',
            'onBlur',
            'onChange',
            'onClick',
            'onKeyDown',
            'onKeyPress',
            'onKeyUp',
            'onMouseDown',
            'placeholder',
            'readOnly',
            'required',
            'role',
            'spellCheck',
            'style'
        ];

        let inputProps = {
            'aria-invalid': props.error ? true : null,
            'aria-required': props.required ? true : null,
            className: getInputClassName(),
            disabled: props.disabled || props.blocked,
            onFocus: handleFocus,
            ref: inputRef,
            type: type,
            value: isValueShown() ? props.value : ''
        };

        if (props.numbersOnly) {
            inputProps.onKeyDown = handleKeyDown;

            if (props.keyUp) {
                inputProps.onKeyUp = props.keyUp;
            }
        }

        return assignIn(pick(props, inputWhiteList), inputProps);
    };

    const renderAdditionalOption = () => {
        let dataToRender = null;

        if (props.showPasswordButtonEnabled) {
            dataToRender = (
                <div className={getShowPasswordButtonClass()}>
                    <ShowPasswordButton {...getShowPasswordButtonProps()} />
                </div>
            );
        } else if (props.showRemoveOption) {
            dataToRender = (
                <div className={getRemoveButtonContainerClass()}>
                    <Button {...getRemoveButtonProps()}>{props.removeButtonText}</Button>
                </div>
            );
        }

        return dataToRender;
    };

    const renderIcon = () => {
        let icon = null;

        if (props.suffixIcon || props.error) {
            icon = <Icon {...getIconProps()} />;

            if (props.iconSeparator) {
                icon = <div {...getIconSeparatorProps()}>{icon}</div>;
            }
        }

        return icon;
    };

    const renderSuffixText = () => {
        const suffixText = getSuffixText();
        let suffixTextNode = null;

        if (suffixText.length) {
            suffixTextNode = <div className="input--suffix-text">{suffixText}</div>;
        }

        return suffixTextNode;
    };

    return (
        <div className={getClass()}>
            <input {...getProps()} />
            {renderSuffixText()}
            {renderIcon()}
            {renderAdditionalOption()}
        </div>
    );
});

Input.propTypes = {
    'aria-activedescendant': PropTypes.string,
    'aria-autocomplete': PropTypes.string,
    'aria-controls': PropTypes.string,
    'aria-describedby': PropTypes.string,
    'aria-expanded': PropTypes.bool,
    'aria-invalid': PropTypes.bool,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-owns': PropTypes.string,
    'aria-required': PropTypes.bool,
    'aria-valuemax': PropTypes.number,
    'aria-valuemin': PropTypes.number,
    'aria-valuenow': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ariaLabelRemoveButton: PropTypes.string,
    autoCapitalize: PropTypes.oneOf(['characters', 'none', 'sentences', 'words']),
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.oneOf(['off', 'on']),
    autoFocus: PropTypes.bool,
    blocked: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    iconDark: PropTypes.bool,
    iconSeparator: PropTypes.bool,
    iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputType: PropTypes.oneOf([
        'primary',
        'primary-simple',
        'secondary',
        'secondary-simple',
        'secondary-extra-small',
        'secondary-small',
        'tertiary'
    ]),
    keyDown: PropTypes.func,
    keyUp: PropTypes.func,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    numberFormatted: PropTypes.bool,
    numbersOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onIconSeparatorClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeButtonText: PropTypes.string,
    required: PropTypes.bool,
    role: PropTypes.string,
    selectAllOnFocus: PropTypes.bool,
    showPasswordButtonEnabled: PropTypes.bool,
    showRemoveOption: PropTypes.bool,
    spellCheck: PropTypes.bool,
    suffixIcon: PropTypes.string,
    suffixTextLength: PropTypes.number,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    type: PropTypes.string,
    value: PropTypes.string
};

Input.defaultProps = assignIn(
    {
        autoCapitalize: 'none',
        autoComplete: 'off',
        autoCorrect: 'off',
        disabled: false,
        error: false,
        iconDark: false,
        iconSeparator: false,
        iconSize: 'small',
        inputType: 'primary',
        readOnly: false,
        required: false,
        selectAllOnFocus: false,
        spellCheck: false,
        textAlign: 'left',
        type: 'text'
    },
    getConfig('Input')
);

export default Input;
