import { BrowserRouter, Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ProtectedRoute from '../protectedRoute';

const ProtectedRouteSet = props => {
    const { routes, session } = props;
    const [isAuth, setIsAuth] = useState(undefined);

    const validateAuth = () => {
        if (!isEmpty(session)) {
            setIsAuth(dayjs().isBefore(dayjs.unix(session.expiration)));
        } else {
            setIsAuth(false);
        }
    };

    useEffect(() => {
        validateAuth();
    });

    const getProtectRouteProps = route => {
        return {
            exact: route.exact,
            path: route.path,
            redirectPath: route.redirectPath,
            redirectType: route.redirectType,
            shouldRender: route.type === 'HOT' ? isAuth : !isAuth
        };
    };

    const renderProtectedRoutes = () => {
        let renderRoutes = null;

        if (isAuth !== undefined && routes.length > 0) {
            renderRoutes = routes.map(route => {
                return (
                    <ProtectedRoute key={route.path} {...getProtectRouteProps(route)}>
                        {route.component}
                    </ProtectedRoute>
                );
            });
        }

        return renderRoutes;
    };

    return (
        <BrowserRouter>
            <Switch>{renderProtectedRoutes()}</Switch>
        </BrowserRouter>
    );
};

ProtectedRouteSet.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            redirectPath: PropTypes.string.isRequired,
            redirectType: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['COLD', 'HOT']).isRequired
        })
    ).isRequired,
    session: PropTypes.shape({
        access_token: PropTypes.string,
        expiration: PropTypes.number,
        expires_in: PropTypes.number,
        id_token: PropTypes.string,
        scope: PropTypes.string,
        token_type: PropTypes.string
    })
};

export default ProtectedRouteSet;
