const browserUtils = {
    getWindowHorizontalScroll () {
        let horizontalScroll = 0;

        horizontalScroll = window.pageXOffset
            ? window.pageXOffset
            : (document.documentElement || document.body).scrollLeft;

        return horizontalScroll;
    },

    getWindowVerticalScroll () {
        let horizontalScroll = 0;

        horizontalScroll = window.pageYOffset
            ? window.pageYOffset
            : (document.documentElement || document.body).scrollTop;

        return horizontalScroll;
    }
};

export default browserUtils;