import HomePage from 'pages/homePage';
import LoginPage from 'pages/loginPage';
import PathConfig from './pathConfig';
import React from 'react';

export default {
    routes: [
        {
            component: <HomePage />,
            exact: false,
            path: PathConfig['homepage'],
            redirectPath: '/',
            redirectType: 'INTERNAL',
            type: 'HOT'
        },
        {
            component: <LoginPage />,
            exact: true,
            path: PathConfig['login'],
            redirectPath: '/home-page/',
            redirectType: 'INTERNAL',
            type: 'COLD'
        }
    ]
};
