import initializeLogger from 'logger';
import { logServiceErrors } from 'utils/transformErrorForLogger';
import { post } from 'api/serviceCaller';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const propTypes = {
    headerProps: PropTypes.object
};

const LoggerManager = props => {
    const { headerProps } = props;

    const loggerCallback = async messages => {
        try {
            await post('logging', { messages: messages }, { headerProps: headerProps });
        } catch (error) {
            if (error.response) {
                logServiceErrors(error.response, 'login');
            } else {
                setTimeout(() => {
                    throw error;
                }, 0);
            }
        }
    };

    const configurations = {
        loggerCallback: loggerCallback,
        maxMessagesToQueue: 20,
        reportInterval: 15000,
        uuid: headerProps.uuid
    };

    useEffect(() => {
        initializeLogger(configurations);
    }, []);

    return null;
};

LoggerManager.propTypes = propTypes;

export default LoggerManager;
