import { getSession } from 'modules/session';
import ProtectedRouteSet from 'components/protectedRouteSet';
import React from 'react';
import { routesConfig } from 'applicationConfig/configure';
import { useSelector } from 'react-redux';

const SWANonRevHomePage = () => {
    const session = useSelector(getSession);
    const getProtectedRouteSetProps = () => {
        return {
            routes: routesConfig.routes,
            session: session
        };
    };

    return (
        <main className="swa-nonrev-home-page" role="main">
            <ProtectedRouteSet {...getProtectedRouteSetProps()} />
        </main>
    );
};

export default SWANonRevHomePage;
