import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import { getConfig } from './config/config';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';
import './formControl.scss';

const FormControl = props => {
    const isNotValid = () => {
        return props.errors.length > 0;
    };

    const renderMessage = (message, index) => {
        return !isNotValid() && !props.disabled ? (
            <div className="form-control--message" key={index}>
                {message}
            </div>
        ) : null;
    };

    const renderError = error => {
        return (
            <div className="form-control--error swa-g-error" key={error}>
                {error}
            </div>
        );
    };

    const renderErrors = () => {
        return isNotValid() ? props.errors.map(renderError) : null;
    };

    const renderScreenReaderHelperText = () => {
        const helperTextScreenReaderOnly = props.helperTextScreenReaderOnly;
        let renderNode = null;

        if (helperTextScreenReaderOnly) {
            renderNode = <span className="swa-g-screen-reader-only">{helperTextScreenReaderOnly}</span>;
        }

        return renderNode;
    };

    const renderHelperText = () => {
        const helperText = props.helperText;
        let renderNode = null;

        if (helperText) {
            renderNode = (
                <span>
                    <span className="form-control--helper-text" aria-hidden>
                        {' '}
                        {helperText}
                    </span>
                    {renderScreenReaderHelperText()}
                </span>
            );
        }

        return renderNode;
    };

    const renderRequiredOrOptional = () => {
        const indicatorType = props.indicatorType;
        const optionalLabel = props.optionalLabel;
        const required = props.required;
        let renderNode = null;

        if (indicatorType === 'required' && required) {
            renderNode = (
                <span className="form-control--required" aria-hidden>
                    {' '}
                    *
                </span>
            );
        } else if (indicatorType === 'optional' && !required) {
            renderNode = <span className="form-control--helper-text"> {optionalLabel}</span>;
        }

        return renderNode;
    };

    const renderIndicator = () => {
        return props.showRequiredOrOptional ? renderRequiredOrOptional() : null;
    };

    const renderLabelText = () => {
        let content = props.labelText;

        if (isFunction(content)) {
            content = content();
        }

        return content;
    };

    const renderDescription = () => {
        const description = props.description;

        return description ? <span className="form-control--description"> {description}</span> : null;
    };

    const getClass = () => {
        const inputType = props.inputType;
        const width = props.width;

        const classes = {
            'form-control': true,
            'form-control_disabled': props.disabled || props.blocked,
            'form-control_error': isNotValid(),
            'form-control_full': width === 'full',
            'form-control_large': width === 'large',
            'form-control_last': props.lastInput,
            'form-control_medium': width === 'medium',
            'form-control_micro': width === 'micro',
            'form-control_no-left-margin': props.noLeftMargin,
            'form-control_no-min-height': props.noMinHeight,
            'form-control_primary': inputType === 'primary',
            'form-control_primary-simple': inputType === 'primary-simple',
            'form-control_secondary': inputType === 'secondary',
            'form-control_secondary-simple': inputType === 'secondary-simple',
            'form-control_secondary-small': inputType === 'secondary-small',
            'form-control_small': width === 'small',
            'form-control_tertiary': inputType === 'tertiary'
        };

        return classNames(classes, props.className);
    };

    const Wrapper = props.wrapInLabel ? 'label' : 'div';

    return (
        <div className={getClass()}>
            {renderDescription()}
            <Wrapper>
                <span className="form-control--label" aria-hidden={props.labelHiddenFromScreenReader}>
                    {renderLabelText()} {renderIndicator()} {renderHelperText()}
                </span>
                <div>{props.children}</div>
                <div className="form-control--errors">{renderErrors()}</div>
                {props.messages.map(renderMessage)}
            </Wrapper>
        </div>
    );
};

FormControl.propTypes = {
    blocked: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    description: PropTypes.node,
    disabled: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    helperText: PropTypes.string,
    helperTextScreenReaderOnly: PropTypes.string,
    indicatorType: PropTypes.oneOf(['required', 'optional']),
    inputType: PropTypes.oneOf([
        'primary',
        'primary-simple',
        'secondary',
        'secondary-simple',
        'secondary-small',
        'tertiary'
    ]),
    labelHiddenFromScreenReader: PropTypes.bool,
    labelText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    lastInput: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.string),
    noLeftMargin: PropTypes.bool,
    noMinHeight: PropTypes.bool,
    optionalLabel: PropTypes.string,
    required: PropTypes.bool,
    showRequiredOrOptional: PropTypes.bool,
    width: PropTypes.oneOf(['full', 'large', 'medium', 'micro', 'none', 'small']),
    wrapInLabel: PropTypes.bool
};

FormControl.defaultProps = assignIn(
    {
        errors: [],
        indicatorType: 'required',
        labelText: '',
        messages: [],
        showRequiredOrOptional: true,
        width: 'large',
        wrapInLabel: true
    },
    getConfig('FormControl')
);

export default FormControl;
