import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import filterDOMProps from '../utils/dom-whitelist/filter-dom-props';
import { getConfig } from './config/config';
import Icon from './icon';
import PropTypes from 'prop-types';
import React, { useEffect, forwardRef } from 'react';
import './actionable.scss';

const Actionable = forwardRef((props, ref) => {
    useEffect(() => {
        if (props.iconOnly && !(props['aria-label'] || props['aria-hidden'] || props['aria-labelledby'])) {
            console.warn('Add aria-label to this this component', ref.current);
        }
    }, [props, ref]);

    const hasNotOutline = () => {
        const actionableType = props.actionableType;

        return (
            actionableType === 'primary' ||
            actionableType === 'secondary' ||
            actionableType === 'secondary-dark-affix' ||
            actionableType === 'tertiary' ||
            actionableType === 'tertiary-light'
        );
    };

    const getActionableTypeClass = () => {
        const actionableTypeClasses = {
            advertisement: ['actionable_advertisement'],
            'body-text-dark': ['actionable_body-text-dark'],
            'body-text-light': ['actionable_body-text-light'],
            bright: ['actionable_bright'],
            'bright-base': ['actionable_bright-base'],
            'bright-light': ['actionable_bright-light'],
            'bright-light-affix': ['actionable_bright-light-affix'],
            'bright-simple': ['actionable_bright-simple'],
            'bright-strong': ['actionable_bright-strong'],
            close: ['actionable_close'],
            'close-light': ['actionable_close-light'],
            'close-small': ['actionable_close-small'],
            dark: ['actionable_dark'],
            'dark-simple': ['actionable_dark-simple'],
            'dark-strong': ['actionable_dark-strong'],
            heading: ['actionable_heading'],
            'horizontal-action-list': ['actionable_horizontal-action-list'],
            'horizontal-action-list-light': [
                'actionable_horizontal-action-list',
                'actionable_horizontal-action-list-light'
            ],
            informational: ['actionable_informational'],
            light: ['actionable_light'],
            'light-simple': ['actionable_light-simple'],
            'light-strong': ['actionable_light-strong'],
            primary: ['actionable_primary'],
            secondary: ['actionable_secondary'],
            'secondary-dark-affix': ['actionable_secondary-dark-affix'],
            tab: ['actionable_tab'],
            'tab-light': ['actionable_tab-light'],
            'tab-light-bordered': ['actionable_tab-light-bordered'],
            'tab-light-bordered-large': ['actionable_tab-light-bordered-large'],
            'tab-strong': ['actionable_tab-strong'],
            tertiary: ['actionable_tertiary'],
            'tertiary-light': ['actionable_tertiary-light'],
            toggle: ['actionable_toggle'],
            'vertical-menu': ['actionable_vertical-menu']
        };

        return classNames(actionableTypeClasses[props.actionableType]);
    };

    const getIconClass = iconType => {
        const iconGap = props.iconGap;

        const classes = {
            'actionable--icon': true,
            'actionable--icon_inherit-color': props.iconInheritsColor,
            'actionable--icon_large-gap': iconGap === 'large',
            'actionable--icon_medium-gap': iconGap === 'medium',
            'actionable--icon_prefix': iconType === 'prefixIcon',
            'actionable--icon_rotation': props.iconRotated,
            'actionable--icon_small-gap': iconGap === 'small',
            'actionable--icon_suffix': iconType === 'suffixIcon'
        };

        return classNames(classes);
    };

    const getClass = () => {
        const iconOnly = props.iconOnly;
        const linkSize = props.linkSize;
        const htmlTag = props.htmlTag;
        const actionableTypeClass = getActionableTypeClass();
        const classes = {
            actionable: true,
            actionable_button: htmlTag === 'button',
            'actionable_external-link': props.externallyLinked,
            'actionable_extra-small-link': linkSize === 'extra-small',
            actionable_first: props.first,
            'actionable_full-width': props.fullWidthSized,
            'actionable_horizontal-padding-extra-large': props.horizontalPadding === 'extra-large',
            'actionable_horizontal-padding-large': props.horizontalPadding === 'large',
            'actionable_horizontal-padding-medium': props.horizontalPadding === 'medium',
            'actionable_horizontal-padding-none': props.horizontalPadding === 'none',
            'actionable_horizontal-padding-small': props.horizontalPadding === 'small',
            'actionable_icon-only': iconOnly,
            'actionable_large-button': props.largeSized,
            'actionable_large-link': linkSize === 'large',
            actionable_last: props.last,
            actionable_link: htmlTag === 'a',
            'actionable_medium-link': linkSize === 'medium',
            'actionable_micro-link': linkSize === 'micro',
            actionable_nested: props.nested,
            'actionable_no-outline': hasNotOutline(),
            'actionable_no-padding': props.noPadding,
            actionable_prefix: props.prefixIcon && !iconOnly,
            actionable_separator: props.separator,
            'actionable_small-link': linkSize === 'small',
            actionable_suffix: props.suffixIcon && !iconOnly,
            'actionable_vertical-padding-large': props.verticalPadding === 'large',
            'swa-g-activated': props.activated,
            'swa-g-disabled': props.disabled,
            'swa-g-error': props.errored,
            'swa-g-highlighted': props.highlighted,
            'swa-g-selected': props.selected,
            'swa-g-toggled': props.toggled
        };

        return classNames(classes, actionableTypeClass, props.className);
    };

    const getIconProps = iconType => {
        let iconProps = {
            className: getIconClass(iconType)
        };

        if (iconType === 'prefixIcon') {
            iconProps['aria-label'] = props.prefixIconAriaLabel;
            iconProps.size = props.prefixIconSize;
            iconProps.icon = props.prefixIcon;
        } else {
            iconProps['aria-label'] = props.suffixIconAriaLabel;
            iconProps.size = props.suffixIconSize;
            iconProps.icon = props.suffixIcon;
        }

        return iconProps;
    };

    const getProps = () => {
        let additionalProps = assignIn({}, props, {
            className: getClass()
        });

        if (props.htmlTag === 'a') {
            additionalProps.disabled = null;
        }

        if (props.analyticsValue) {
            additionalProps['data-a'] = props.analyticsValue;
        }

        return filterDOMProps.html(additionalProps);
    };

    const hasIcon = iconType => {
        return props[iconType];
    };
    const renderDescription = () => {
        let textDescription = null;

        if (props.showDescription) {
            textDescription = (
                <span className="swa-g-screen-reader-only">
                    {String.fromCharCode(160) + props.additionalDescription}
                </span>
            );
        }

        return textDescription;
    };

    const renderChildren = () => {
        return props.children ? <span className="actionable--text">{props.children}</span> : null;
    };

    const renderIcon = iconType => {
        return hasIcon(iconType) ? <Icon {...getIconProps(iconType)} /> : null;
    };

    const Component = props.htmlTag;

    return (
        <Component ref={ref} {...getProps()}>
            {renderIcon('prefixIcon')}
            {renderChildren()}
            {renderDescription()}
            {renderIcon('suffixIcon')}
        </Component>
    );
});

Actionable.propTypes = {
    actionableType: PropTypes.oneOf([
        'advertisement',
        'body-text-dark',
        'body-text-light',
        'bright',
        'bright-base',
        'bright-light',
        'bright-light-affix',
        'bright-simple',
        'bright-strong',
        'close',
        'close-light',
        'dark',
        'dark-strong',
        'dark-simple',
        'heading',
        'horizontal-action-list',
        'horizontal-action-list-light',
        'informational',
        'light',
        'light-simple',
        'light-strong',
        'primary',
        'secondary',
        'secondary-dark-affix',
        'tab',
        'tab-light',
        'tab-light-bordered',
        'tab-light-bordered-large',
        'tab-strong',
        'tertiary',
        'tertiary-light',
        'toggle',
        'vertical-menu'
    ]),
    activated: PropTypes.bool,
    additionalDescription: PropTypes.string,
    analyticsValue: PropTypes.string,
    'aria-hidden': PropTypes.bool,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errored: PropTypes.bool,
    externallyLinked: PropTypes.bool,
    first: PropTypes.bool,
    fullWidthSized: PropTypes.bool,
    highlighted: PropTypes.bool,
    horizontalPadding: PropTypes.oneOf(['none', 'small', 'medium', 'large', 'extra-large']),
    href: PropTypes.string,
    htmlTag: PropTypes.oneOf(['button', 'a', 'span']),
    iconGap: PropTypes.oneOf(['small', 'medium', 'large']),
    iconInheritsColor: PropTypes.bool,
    iconOnly: PropTypes.bool,
    iconRotated: PropTypes.bool,
    largeSized: PropTypes.bool,
    last: PropTypes.bool,
    linkSize: PropTypes.string,
    nested: PropTypes.bool,
    noPadding: PropTypes.bool,
    prefixIcon: PropTypes.string,
    prefixIconAriaLabel: PropTypes.string,
    prefixIconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
    separator: PropTypes.bool,
    showDescription: PropTypes.bool,
    suffixIcon: PropTypes.string,
    suffixIconAriaLabel: PropTypes.string,
    suffixIconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    toggled: PropTypes.bool,
    verticalPadding: PropTypes.oneOf(['large'])
};

Actionable.defaultProps = assignIn(
    {
        additionalDescription: '',
        htmlTag: 'button',
        iconGap: 'small',
        iconInheritsColor: false,
        showDescription: false
    },
    getConfig('Actionable')
);

export default Actionable;
