import 'react-app-polyfill/ie11';
import createStore from 'modules/configureStore';
import LoggerManager from './components/loggerManager';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { storeConfig } from './applicationConfig/configure';
import SWANonRevHomePage from './components/swaNonRevHomePage';
import 'assets/swa-icon.scss';
import './index.scss';

const { persistor, store } = createStore(storeConfig);

const container = document.getElementById('swa-nonrevenue');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <LoggerManager />
            <SWANonRevHomePage />
        </PersistGate>
    </Provider>
);