import forIn from 'lodash/forIn';
import propsEvent from './props-event';
import propsHtml from './props-html';
import propsSvg from './props-svg';

const ARIA_EXPRESSION = /^aria-[A-Za-z]+$/;

const isAriaProp = name => {
    return ARIA_EXPRESSION.test(name);
};

const isReactProp = name => {
    return name === 'key';
};

const pickValidProps = (props, validProps) => {
    let validatedProps = {};

    forIn(props, (value, name) => {
        if (validProps[name] || propsEvent[name] || isAriaProp(name) || isReactProp(name)) {
            validatedProps[name] = value;
        }
    });

    return validatedProps;
};

const filterDomProps = {
    html: props => {
        return pickValidProps(props, propsHtml);
    },
    svg: props => {
        return pickValidProps(props, propsSvg);
    }
};

export default filterDomProps;
