import includes from 'lodash/includes';

let SPAPathName;

const locationUtils = {
    // Example page identifier: air-booking-search, air-cancel-landing, etc.
    getPageIdentifier () {
        return this.getPagePath().replace(/\//g, '-');
    },

    getPagePath () {
        return this.getPathname().replace(/(^\/|\/$|\.html$)/g, '');
    },

    getPathname () {
        const locationPathname = window.location.pathname;
        let result = window.location.pathname;

        if (SPAPathName) {
            result = SPAPathName;

            if (includes(locationPathname, '.html')) {
                result += locationPathname.slice(locationPathname.lastIndexOf('/') + 1);
            }
        }

        if (result?.slice(-1) === '/') {
            // If the path name ends with /, we will assume /index.html, this way
            // we can operate correctly with both /path/index.html and /path/
            result += 'index.html';
        }

        return result;
    },

    setSPAPath (SPAPath) {
        SPAPathName = SPAPath;
    }
};

export default locationUtils;