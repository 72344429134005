import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import { getConfig } from './config/config';
import filterDomProps from '../utils/dom-whitelist/filter-dom-props';
import Heading from './heading';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import uniqueId from 'lodash/uniqueId';

const Section = props => {
    const { ariaLabeledBy, children, className, headingContent, headingProps, headingSize, sectionGroup } = props;
    const [headingId] = useState(uniqueId('heading-'));

    const getAriaLabelledBy = () => {
        let ariaLabelledBy = ariaLabeledBy;

        if (!ariaLabelledBy && ariaLabelledBy !== null) {
            ariaLabelledBy = headingId;
        }

        return ariaLabelledBy;
    };

    const getClass = () => {
        return classNames('section', className);
    };

    const getHeadingClass = () => {
        return classNames('section--heading', headingProps.className);
    };

    const getSectionAttributes = () => {
        const attributes = assignIn({}, props, {
            'aria-labelledby': getAriaLabelledBy(),
            className: getClass(),
            role: sectionGroup ? 'group' : null
        });

        return filterDomProps.html(attributes);
    };

    const getHeadingProps = () => {
        let updatedHeadingProps = assignIn({}, headingProps);

        updatedHeadingProps.className = getHeadingClass();
        updatedHeadingProps.id = headingId;
        updatedHeadingProps.headingFromSection = true;

        if (!updatedHeadingProps.size) {
            updatedHeadingProps.size = headingSize;
        }

        return updatedHeadingProps;
    };

    return (
        <section {...getSectionAttributes()}>
            <Heading {...getHeadingProps()}>{headingContent}</Heading>
            {children}
        </section>
    );
};

Section.propTypes = {
    ariaLabeledBy: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    headingContent: PropTypes.node.isRequired,
    headingLevel: PropTypes.number,
    headingProps: PropTypes.object,
    headingSize: PropTypes.string,
    sectionGroup: PropTypes.bool
};

Section.defaultProps = assignIn({}, getConfig('Section'));

export default Section;
