let cache = {};
let retrievalFunction = window.require;

export const setRetrievalFunction = callback => {
    retrievalFunction = callback;
};

export const put = (dataKey, value) => {
    cache[dataKey] = value;
};

export const clearCache = () => {
    cache = {};
};

export const getBootstrapData = dataKey => {
    let result = cache[dataKey];

    if (!result) {
        result = retrievalFunction(dataKey);
        cache[dataKey] = result;
    }

    return result;
};
