import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import filterDOMProps from '../utils/dom-whitelist/filter-dom-props';
import { getConfig } from './config/config';
import PropTypes from 'prop-types';
import React from 'react';
import './heading.scss';

const Heading = props => {
    const { children, className, headingLevelFromSection, headingFromSection, size } = props;

    const getHeadingLevel = () => {
        const maxHeadingLevel = 6;
        let headingLevel = headingLevelFromSection || 1;

        if (!headingFromSection && headingLevel + 1 <= maxHeadingLevel) {
            headingLevel += 1;
        }

        return headingLevel;
    };

    const getClass = () => {
        const headingSizes = ['jumbo', 'over-large', 'large', 'semi-large', 'medium', 'small', 'extra-small'];
        const headingSize = size || headingSizes[getHeadingLevel() - 1];
        const classes = {
            heading: true,
            'heading_extra-small': headingSize === 'extra-small',
            heading_jumbo: headingSize === 'jumbo',
            heading_large: headingSize === 'large',
            heading_medium: headingSize === 'medium',
            'heading_over-large': headingSize === 'over-large',
            'heading_semi-large': headingSize === 'semi-large',
            heading_small: headingSize === 'small'
        };

        return classNames(classes, className);
    };

    const getAttributes = () => {
        const elementAttributes = assignIn({}, props, {
            className: getClass()
        });

        return filterDOMProps.html(elementAttributes);
    };

    const Element = 'h' + getHeadingLevel();

    return <Element {...getAttributes()}>{children}</Element>;
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    headingFromSection: PropTypes.bool,
    headingLevelFromSection: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    size: PropTypes.oneOf(['jumbo', 'over-large', 'large', 'semi-large', 'medium', 'small', 'extra-small'])
};

Heading.defaultProps = assignIn({}, getConfig('Heading'));

export default Heading;
