import { LOGIN_USER_SUCCESS, LOGOUT_USER } from 'modules/login';

const reducer = (state = {}, action = undefined) => {
    switch (action.type) {
        case LOGIN_USER_SUCCESS:
            return { ...state, ...action.payload };
        case LOGOUT_USER:
            return {};
        default:
            return state;
    }
};

export default reducer;
