import assignIn from 'lodash/assignIn';
import classNames from 'classnames';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './background.scss';

const propTypes = {
    backgroundAnimation: PropTypes.shape({
        delay: PropTypes.number,
        enabled: PropTypes.bool,
        speed: PropTypes.oneOf(['fast', 'immediate', 'normal', 'slow', 'very-fast', 'very-slow'])
    }),
    children: PropTypes.node,
    className: PropTypes.string,
    classNameWrapper: PropTypes.string,
    hasOffsetBottom: PropTypes.bool,
    hasOffsetTop: PropTypes.bool,
    offsetBottom: PropTypes.number,
    offsetTop: PropTypes.number,
    src: PropTypes.string,
    styles: PropTypes.object
};

const defaultProps = {
    hasOffsetTop: true,
    offsetTop: 25,
    src: ''
};

const Background = props => {
    const {
        backgroundAnimation,
        children,
        className,
        classNameWrapper,
        hasOffsetBottom,
        hasOffsetTop,
        offsetBottom,
        offsetTop,
        src,
        styles
    } = props;

    const [backgroundImageReady, setBackgroundImageReady] = useState(false);
    const [animationEnabled, setAnimationEnabled] = useState(false);

    useEffect(() => {
        setAnimationEnabled(get(backgroundAnimation, 'enabled', false));
    }, [backgroundAnimation]);

    useEffect(() => {
        const handleBackgroundImage = () => {
            setBackgroundImageReady(true);
        };

        if (animationEnabled && !backgroundImageReady) {
            let image = new Image();

            if (!backgroundImageReady) {
                image.onload = handleBackgroundImage;
                image.src = src;
            }
        }
    }, [animationEnabled, backgroundImageReady]);

    const getOffset = (offset, hasOffset) => {
        let adjustedOffset = offset;

        if (!hasOffset) {
            adjustedOffset = 0;
        } else if (adjustedOffset < 25) {
            adjustedOffset = 25;
        }

        adjustedOffset = adjustedOffset + 'px';

        return adjustedOffset;
    };

    const shouldDisplayImage = () => {
        return src && ((animationEnabled && backgroundImageReady) || !animationEnabled);
    };

    const getStyles = () => {
        const style = {};

        if (styles) {
            forOwn(styles, (value, key) => {
                style[key] = value;
            });
        }

        return style;
    };

    const getStyle = () => {
        const paddingBottom = getOffset(offsetBottom, hasOffsetBottom);
        const paddingTop = getOffset(offsetTop, hasOffsetTop);
        let style = backgroundAnimation ? {} : { paddingBottom: paddingBottom, paddingTop: paddingTop };

        if (shouldDisplayImage()) {
            style.backgroundImage = 'url(' + src + ')';
        }

        return assignIn({}, style, getStyles());
    };

    const getSpeed = speed => {
        const speeds = {
            fast: '0.15s',
            immediate: '0',
            normal: '0.3s',
            slow: '0.5s',
            'very-fast': '0.05s',
            'very-slow': '0.8s'
        };

        return speeds[speed] ? speeds[speed] : speed;
    };

    const getClasses = () => {
        return classNames(
            {
                background: true
            },
            className
        );
    };

    const getContentWrapperProps = () => {
        const backgroundColorKey = get(styles, 'backgroundColor', undefined);
        const backgroundColor = backgroundImageReady ? 'transparent' : backgroundColorKey;
        const delay = get(backgroundAnimation, 'delay', 0);
        const speed = get(backgroundAnimation, 'speed', 'normal');

        return {
            className: classNameWrapper,
            style: {
                backgroundColor: backgroundColor,
                transition: 'background-color ' + getSpeed(speed) + ' ease ' + delay + 's'
            }
        };
    };

    const getContentWrapper = () => {
        return <div {...getContentWrapperProps()}>{children}</div>;
    };

    const getContent = () => {
        return animationEnabled ? getContentWrapper() : children;
    };

    const getProps = () => {
        return {
            className: getClasses(),
            style: getStyle()
        };
    };

    return <div {...getProps()}>{getContent()}</div>;
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
